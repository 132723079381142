import type { ReactNode } from "react";
import { Grid2 as Grid, Divider } from "@mui/material";

function DividerGrid(): ReactNode {
  return (
    <Grid size={12}>
      <Divider sx={{ transform: "scale(0.95)" }} />
    </Grid>
  );
}

export default DividerGrid;
