import { type ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import type { DossierEau } from "models";
import { CourriersDossierEauDefinitions } from "models";
import { withPageErrorBoundary } from "utils/errorHandling";
import { useDossier, useChantier } from "providers";
import DossierSections from "../DossierSections";
import { dossierEauSections } from "../../routes/dossiersSectionsDefinitions";
import { DossierCourriersForm } from "../Courriers";

function DossierEauFormCourriers(): ReactNode {
  const { chantier, isLoading: isChantierLoading } = useChantier();
  const { dossier } = useDossier<DossierEau>();

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierEauSections} noSaveButton />
      <DossierCourriersForm
        chantier={chantier}
        dossier={dossier}
        isLoading={isChantierLoading || dossier == null}
        courriersMetadata={CourriersDossierEauDefinitions}
      />
    </Grid>
  );
}

export const DossierEauFormCourriersWithErrorBoundary =
  withPageErrorBoundary(DossierEauFormCourriers);
