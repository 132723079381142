import { useCallback, useEffect, useState, type ReactNode } from "react";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import DossierSections from "../DossierSections";
import { dossierCipaSections } from "../../routes/dossiersSectionsDefinitions";
import { useDossier } from "providers";
import { ControleRapportStatus } from "models";
import type { ControleRapport, DossierCipa } from "models";
import { Button, Grid2 as Grid, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import { controlesService } from "services";
import { dateUtil } from "@sdeapps/react-core";
import { FormSection } from "components/Layout";
import { ControleCipaProvider } from "pages/Chantier/providers/ControleCipaProvider";
import { ControleCipaAccordion } from "../Controles";

/**
 * Permet de calmer notamment Sonar étant donné le cas d'usage d'un ternaire imbriqué.
 * Construit un tableau avec au moins un élément pour afficher un skeleton par rapport existant.
 * @returns Une liste d'objet avec uniquement un `id` permettant de construire une liste de squelette à afficher.
 */
function getIdsToLoadOrDefaultOne(rapports: Array<ControleRapport>): Array<{ id?: string }> {
  return (rapports.length > 0 ? rapports : [{ id: undefined }]).map(({ id }) => ({ id }));
}

function DossierCipaFormControles(): ReactNode {
  const { dossier } = useDossier<DossierCipa>();
  const [rapports, setRapports] = useState<Array<ControleRapport>>([]);
  const { catchErrors, isLoading } = useErrorHandler({ defaultIsLoading: true });
  const loadDossierControles = useCallback(async () => {
    setRapports([]);
    if (dossier != null) {
      const { idChantier, id: idDossier } = dossier;
      void catchErrors(async () => {
        const result = await controlesService.getDossierControles(idChantier, idDossier);
        setRapports(result);
      });
    }
  }, [catchErrors, dossier]);
  useEffect(() => {
    void loadDossierControles();
  }, [dossier, loadDossierControles]);
  const addNewControle = useCallback(async () => {
    if (dossier == null) {
      console.error("Le dossier ne peut être indéfini");
      return;
    }

    if (rapports.length > 0) {
      const rapportsByDateControleDesc = rapports
        .filter((r) => r.id != null && r.id !== "")
        .map<{ id: string; dateControle: string }>(({ id, dateControle }) => ({ id, dateControle }))
        .sort((a, b) => dateUtil.compareDesc(a.dateControle, b.dateControle));

      if (rapportsByDateControleDesc.length > 0) {
        const { idChantier, id: idDossier } = dossier;
        const lastRapportId: string = rapportsByDateControleDesc[0].id;
        void catchErrors(async () => {
          await controlesService.cloneDossierControle(lastRapportId, idChantier, idDossier);
          await loadDossierControles();
        });
        return;
      }
    }

    setRapports([
      {
        version: -1,
        id: "",
        dossierCipaId: dossier.id,
        dateControle: dateUtil.format(new Date(), "yyyy-MM-dd"),
        status: ControleRapportStatus.NonConforme,
        details: [],
      },
      ...rapports,
    ]);
  }, [dossier, rapports, catchErrors, loadDossierControles]);

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierCipaSections} noSaveButton />

      {dossier != null && (
        <Grid container spacing={2} size={12}>
          <FormSection size={12} noBackground>
            <Grid container size={12}>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <Button variant="contained" onClick={addNewControle} startIcon={<AddIcon />}>
                Nouveau contrôle
              </Button>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <IconButton onClick={loadDossierControles} loading={isLoading}>
                <Tooltip title="Rafraîchir" placement="bottom">
                  <SyncIcon />
                </Tooltip>
              </IconButton>
            </Grid>
            {isLoading
              ? getIdsToLoadOrDefaultOne(rapports).map((rapport, index) => (
                  <Grid container size={12} key={rapport.id ?? `squelette-rapport-${index}`}>
                    <Skeleton height={100} sx={{ width: "100%", mt: -2, mb: -2 }} />
                  </Grid>
                ))
              : rapports.map((rapport, index) => (
                  <ControleCipaProvider
                    key={`rapport-${rapport.id}-${index}`}
                    idDossier={dossier.id}
                    idChantier={dossier.idChantier}
                    rapport={rapport}
                    version={rapport.version}>
                    <ControleCipaAccordion isDefaultExpanded={index === 0} />
                  </ControleCipaProvider>
                ))}
          </FormSection>
          {rapports.length === 0 && !isLoading && (
            <FormSection size={12} sx={{ p: 2, pt: 0 }}>
              <Typography>Ce Dossier n'a pour l'instant aucun rapport de visite.</Typography>
            </FormSection>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export const DossierCipaFormControlesWithErrorBoundary =
  withPageErrorBoundary(DossierCipaFormControles);
