import type { DossierCipa, FacturationCipa, TarifCipaBase } from "models";
import { dossierCipaSections } from "pages/Chantier/routes/dossiersSectionsDefinitions";
import { useEffect, useMemo, type ReactNode } from "react";
import { SdeappsError, useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import DossierSections from "../DossierSections";
import Grid from "@mui/material/Grid2";
import { FormSection } from "components/Layout";
import { grey } from "@mui/material/colors";
import { tarifsCipaService } from "services/tarifsCipaService";
import { TarifsCipa } from "constants/TarifsCipa";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ControlledNumberField } from "components/Inputs";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { MontantTypography } from "../AspectFinancier";
import { facturationCipaService } from "services";
import { useDossier } from "providers";
import { LoadingScreen } from "components/Loading";
import { ToastMessages } from "enums";
import { enqueueSnackbar } from "notistack";
import { dateUtil } from "@sdeapps/react-core";
import InfoIcon from "@mui/icons-material/Info";

interface FacturationForm {
  facturationItems: Array<FacturationFormItem>;
}

interface FacturationFormItem extends TarifCipaBase {
  montant?: number;
  quantite?: number;
}

function DossierCipaFormFacturation(): ReactNode {
  const { catchErrors, isLoading } = useErrorHandler({ defaultIsLoading: false, dontThrow: true });
  const { dossier, isLoading: isDossierLoading } = useDossier<DossierCipa>();
  const anneeCreationDossier = useMemo(() => {
    return dossier != null ? dateUtil.getDate(dossier.dateDemande).getFullYear() : null;
  }, [dossier]);

  const formMethods = useForm<FacturationForm>({
    defaultValues: {
      facturationItems: [],
    },
  });

  const { control, handleSubmit } = formMethods;
  const facturationItems = useWatch({ control, name: "facturationItems" });

  const { fields, replace } = useFieldArray({
    name: "facturationItems",
    control,
  });

  useEffect(() => {
    if (dossier == null) {
      return;
    }

    async function getFacturationItems(): Promise<void> {
      if (dossier != null && anneeCreationDossier != null) {
        const tarifs = await tarifsCipaService.getByAnnee(anneeCreationDossier);
        const facturation = await facturationCipaService.getByDossier(
          dossier.idChantier,
          dossier.id
        );

        const facturationItems: Array<FacturationFormItem> = TarifsCipa.reduce<
          Array<FacturationFormItem>
        >((tarifsAvecDescriptions, tarif) => {
          const tarifAvecDescription: FacturationFormItem = {
            ...tarif,
            montant: tarifs[tarif.code],
            quantite: facturation.facturationItems[tarif.code] ?? 0,
          };
          tarifsAvecDescriptions.push(tarifAvecDescription);
          return tarifsAvecDescriptions;
        }, []);

        replace(facturationItems);
      }
    }

    void catchErrors(getFacturationItems);
  }, [anneeCreationDossier, catchErrors, dossier, replace]);

  const total = useMemo(() => {
    return facturationItems.reduce(
      (sum, item) => sum + (item.montant ?? 0) * (item.quantite ?? 0),
      0
    );
  }, [facturationItems]);

  const sendData: SubmitHandler<FacturationForm> = async (form) => {
    if (dossier == null) {
      throw new SdeappsError("DOSSIER NE PEUT PAS ETRE NUL !!!!");
    }

    const data: Record<string, number> = {};
    for (const facturationItem of form.facturationItems) {
      if (facturationItem.quantite !== undefined && facturationItem.quantite !== 0)
        data[facturationItem.code] = facturationItem.quantite;
    }

    const payload: FacturationCipa = {
      facturationItems: data,
    };

    void catchErrors(
      async () => {
        await facturationCipaService.replaceFacturation(dossier?.idChantier, dossier?.id, payload);

        enqueueSnackbar({
          variant: "success",
          message: ToastMessages.UPDATED_FACTURATION,
        });
      },
      () => {
        enqueueSnackbar({
          variant: "error",
          message: ToastMessages.ERROR_RETRY,
        });
      }
    );
  };

  return (
    <Grid container>
      <DossierSections isLoading={isDossierLoading} sections={dossierCipaSections} noSaveButton />

      {dossier == null || isDossierLoading ? (
        <LoadingScreen />
      ) : (
        <FormProvider {...formMethods}>
          <Grid
            container
            spacing={2}
            sx={{ p: 2, background: grey[200] }}
            size={12}
            component="form"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(sendData, () => {
              enqueueSnackbar({
                variant: "error",
                message: ToastMessages.ERROR_RETRY,
              });
            })}>
            <FormSection title="Facturation" size={12}>
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                  border: `1px solid ${grey[300]}`,
                  position: "relative",
                  // mb: 2,
                }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ paddingY: 2 }}>
                        <Typography fontWeight={600}>Code SAP</Typography>
                      </TableCell>
                      <TableCell sx={{ paddingY: 2 }}>
                        <Typography fontWeight={600}>Nom</Typography>
                      </TableCell>
                      <TableCell sx={{ paddingY: 2 }}>
                        <Typography fontWeight={600}>
                          Montant{" "}
                          <Tooltip
                            title={`Tarifs de l'année de création du dossier (${anneeCreationDossier})`}
                            placement="bottom"
                            disableInteractive>
                            <InfoIcon sx={{ fontSize: "0.75rem", verticalAlign: "super" }} />
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ paddingY: 2 }}>
                        <Typography fontWeight={600}>Quantité</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((facturationItem, index) => (
                      <TableRow key={facturationItem.code}>
                        <TableCell>
                          <Typography>{facturationItem.code}</Typography>
                        </TableCell>
                        <TableCell>
                          <TableRow>
                            <Typography>{facturationItem.description}</Typography>
                          </TableRow>
                          <TableRow>
                            <Typography variant="body2" color="grey">
                              {facturationItem.commentaire}
                            </Typography>
                          </TableRow>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {facturationItem.montant} {facturationItem.unit}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <ControlledNumberField
                            name={`facturationItems.${index}.quantite`}
                            type="entier"
                            textAlign="right"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid
                container
                size={12}
                sx={{ alignItems: "center" }}
                columnSpacing={2}
                justifyContent="flex-end">
                <Grid size={3}>
                  <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
                    Coût total du CIPA
                  </Typography>
                </Grid>
                <Grid size={3}>
                  <MontantTypography numberToFormat={total} emphasis />
                </Grid>
              </Grid>
              <Grid container spacing={2} justifyContent="flex-end" size={12}>
                <Grid size="auto">
                  <Button
                    variant="outlined"
                    type="submit"
                    loading={isLoading}
                    disabled={
                      isDossierLoading ||
                      isLoading ||
                      facturationItems == null ||
                      facturationItems.length === 0
                    }>
                    Enregistrer
                  </Button>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
        </FormProvider>
      )}
    </Grid>
  );
}

export const DossierCipaFormFacturationWithErrorBoundary = withPageErrorBoundary(
  DossierCipaFormFacturation
);
