import type { ControleDetail } from "./ControleDetail";

export enum ControleRapportStatus {
  Conforme = "Conforme",
  NonConforme = "NonConforme",
}

export type ControleRapport = {
  version: number;
  id: string;
  dossierCipaId?: string;
  dateControle: string;
  status: ControleRapportStatus;
  observations?: string;
  details: Array<ControleDetail>;
};

export type ControleRapportWithoutDetails = Omit<ControleRapport, "details">;
