import { useState } from "react";
import type { ReactNode } from "react";
import { Collapse, IconButton, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { Chantier, Demandeur } from "models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ChantierDemandeurCard, DemandeurCivilite } from ".";
import { getDemandeurDisplayName } from "utils/demandeurUtil";

interface ChantierDemandeurLineProps {
  demandeur?: Demandeur;
}

function ChantierDemandeurLine({ demandeur }: Readonly<ChantierDemandeurLineProps>): ReactNode {
  if (demandeur == null) {
    return null;
  }

  return (
    <Grid>
      <Typography variant="subtitle2">
        <DemandeurCivilite civilite={demandeur.civilite} />{" "}
        <Typography color="primary" component="span" variant="inherit">
          {getDemandeurDisplayName(demandeur)}
        </Typography>
      </Typography>
    </Grid>
  );
}

interface ChantierDemandeursProps {
  chantier: Chantier;
}

export function ChantierDemandeurs({ chantier }: Readonly<ChantierDemandeursProps>): ReactNode {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen(): void {
    setIsOpen(!isOpen);
  }

  return (
    <Paper component={Grid} container size={12} variant="outlined" sx={{ border: 0 }}>
      <Grid
        container
        size={12}
        onClick={toggleIsOpen}
        sx={{ cursor: "pointer" }}
        alignItems="center"
        columnSpacing={2}>
        <Grid sx={{ marginRight: -2 }}>
          <IconButton size="small">{isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}</IconButton>
        </Grid>
        <Grid container alignItems="baseline">
          <Typography variant="subtitle2">
            Demandeur{chantier?.demandeur2 != null && "s"}
            {!isOpen && " : "}
          </Typography>

          {!isOpen && (
            <>
              <ChantierDemandeurLine demandeur={chantier.demandeur1} />
              <ChantierDemandeurLine demandeur={chantier.demandeur2} />
            </>
          )}
        </Grid>
      </Grid>

      <Collapse in={isOpen} sx={{ width: "100%" }}>
        <Grid container size={12} spacing={1}>
          <ChantierDemandeurCard demandeur={chantier.demandeur1} />
          <ChantierDemandeurCard demandeur={chantier.demandeur2} />
        </Grid>
      </Collapse>
    </Paper>
  );
}
