import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import { formatMontant } from "utils/financeUtil";

interface MontantTypographyProps {
  numberToFormat: string | number | undefined;
  emphasis?: boolean;
  withEndEuro?: boolean;
}

export function MontantTypography({
  numberToFormat,
  emphasis = false,
  withEndEuro = true,
}: Readonly<MontantTypographyProps>): ReactNode {
  return (
    <Typography
      sx={{
        textAlign: "right",
        p: 2,
        paddingY: 1,
        backgroundColor: emphasis ? "info.light" : undefined,
        fontWeight: emphasis ? 500 : "normal",
        borderRadius: 1,
      }}>
      {formatMontant({}, numberToFormat, withEndEuro)}
    </Typography>
  );
}
