import { numericFormatter } from "react-number-format";
import type { NumericFormatProps } from "react-number-format";

export function formatMontant<InputAttributes>(
  props: NumericFormatProps<InputAttributes>,
  numberToFormat: string | number | undefined = 0,
  withEndEuro: boolean = false
): string {
  return `${numericFormatter(numberToFormat.toString(), {
    thousandSeparator: " ",
    decimalSeparator: ",",
    fixedDecimalScale: true,
    decimalScale: 2,
    ...props,
  })}${withEndEuro ? " €" : ""}`;
}

const financeUtils = { formatMontant };

export default financeUtils;
