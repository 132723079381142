import type { ReactNode, PropsWithChildren } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";

interface AspectFinancierSimpleLineProps extends PropsWithChildren {
  title: ReactNode;
  heavyTitle?: boolean;
}

export function AspectFinancierSimpleLine({
  title = "",
  heavyTitle = false,
  children,
}: Readonly<AspectFinancierSimpleLineProps>): ReactNode {
  return (
    <Grid container size={12} sx={{ alignItems: "center" }} columnSpacing={2}>
      <Grid size={5}>
        <Typography variant={heavyTitle ? "subtitle2" : undefined} sx={{ textAlign: "right" }}>
          {title}
        </Typography>
      </Grid>
      <Grid size={7}>{children}</Grid>
    </Grid>
  );
}
