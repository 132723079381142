import { useMemo } from "react";
import type { ReactNode } from "react";
import { MenuItem } from "@mui/material";
import {
  StatutDossierAss,
  StatutDossierCipa,
  StatutDossierEau,
  StatutDossierPac,
  TypeDossier,
} from "models";
import type { ControlledInput } from "utils/rules";
import type { FieldValues, FieldPath } from "react-hook-form";
import { ControlledTextField } from "./ControlledTextField";

interface ControlledStatutDossierProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label: string;
  placeholder?: string;
  fullWidth?: boolean;
  type: TypeDossier;
}

export function ControlledStatutDossier<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  label,
  defaultValue,
  placeholder = label,
  rules,
  fullWidth = true,
  type,
}: Readonly<ControlledStatutDossierProps<T, Name>>): ReactNode {
  const statutsList = useMemo(() => {
    switch (type) {
      case TypeDossier.EAU:
        return Object.values(StatutDossierEau);
      case TypeDossier.ASS:
        return Object.values(StatutDossierAss);
      case TypeDossier.CIPA:
        return Object.values(StatutDossierCipa);
      case TypeDossier.PAC:
        return Object.values(StatutDossierPac);
    }
  }, [type]);

  return (
    <ControlledTextField
      label={label}
      placeholder={placeholder}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      fullWidth={fullWidth}
      select>
      {statutsList?.map((statut) => (
        <MenuItem key={statut} value={statut}>
          {statut}
        </MenuItem>
      ))}
    </ControlledTextField>
  );
}
