import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2 as Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import type { ReactNode } from "react";
import { ControleCipaDetails, ControleCipaHeader, ControleCipaSummary } from ".";
import { useDossier } from "providers";
import { useControleCipa } from "pages/Chantier/providers/ControleCipaProvider";

interface ControleCipaAccordionProps {
  isDefaultExpanded: boolean;
}
export function ControleCipaAccordion({
  isDefaultExpanded,
}: Readonly<ControleCipaAccordionProps>): ReactNode {
  const { dossier } = useDossier();
  const [isOpen, setIsOpen] = useState<boolean>(isDefaultExpanded);
  const { isWorking } = useControleCipa();

  return (
    <Grid size={12} container spacing={1} sx={{ position: "relative" }}>
      <Accordion
        variant="outlined"
        expanded={isOpen ?? false}
        onChange={() => {
          setIsOpen(!isOpen);
        }}
        sx={(theme) => {
          return {
            position: "relative",
            width: "100%",
            borderColor: `${dossier?.type.toLowerCase()}.main`,
            borderWidth: "2px",
            transition: "box-shadow 150ms ease",
            "&:hover": { boxShadow: isOpen ? 0 : theme.shadows[2] },
          };
        }}>
        <AccordionSummary
          sx={{
            position: "sticky",
            top: "82px",
            background: "white",
            marginLeft: "1px",
            zIndex: 2,
            borderBottom: "transparent 1px solid",
            borderColor: isOpen ? `${dossier?.type.toLowerCase()}.main` : undefined,
            borderRadius: isOpen ? "4px 4px 0 0" : "4px",
          }}>
          <Grid container size={12} alignItems="center" spacing={2}>
            <Grid size="auto">
              <IconButton size="small">
                {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Grid>
            <ControleCipaSummary />
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: grey[200], p: 2, borderRadius: "0 0 4px 4px" }}>
          {isWorking ? (
            <Skeleton height={100} sx={{ width: "100%", mt: -2, mb: -2 }} />
          ) : (
            <Grid container size={12} spacing={2}>
              <ControleCipaHeader />
              <ControleCipaDetails />
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
