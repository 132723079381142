import type { ReactNode } from "react";
import { Checkbox } from "@mui/material";
import type { SxProps } from "@mui/material";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import type { ControlledInput } from "utils/rules";

interface ControlledCheckboxProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  id?: string;
  sx?: SxProps;
}

export function ControlledCheckbox<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  id,
  defaultValue,
  rules,
  sx,
}: Readonly<ControlledCheckboxProps<T, Name>>): ReactNode {
  const {
    field: { value, onChange },
  } = useController({ name, control, rules, defaultValue });

  return (
    <Checkbox
      id={id}
      checked={value ?? false}
      onClick={() => {
        onChange(value !== true);
      }}
      sx={sx}
    />
  );
}
