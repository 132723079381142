import { TypeDemandeur } from "models";
import type { Demandeur, DemandeurParticulier, DemandeurSociete } from "models";

export function isDemandeurParticulier(demandeur?: Demandeur): demandeur is DemandeurParticulier {
  return demandeur?.$type === TypeDemandeur.Particulier;
}

export function isDemandeurSociete(demandeur?: Demandeur): demandeur is DemandeurSociete {
  return demandeur?.$type === TypeDemandeur.Societe;
}

/**
 * Retourne un libellé d'affichage d'un demandeur selon son type :
 * - pour les demandeurs particuliers: NOM Prénom sont utilisés,
 * - pour les demandeurs société: Raison sociale est utilisée,
 * - pour les demandeurs non gérés: une chaine de caractère vide.
 * @param demandeur
 * @returns
 */
export function getDemandeurDisplayName(demandeur?: Demandeur): string {
  if (isDemandeurParticulier(demandeur)) {
    return `${demandeur.nom?.toLocaleUpperCase()} ${demandeur.prenom}`;
  } else if (isDemandeurSociete(demandeur)) {
    return `${demandeur.raisonSociale}`;
  } else {
    return "";
  }
}
