import type { ReactNode } from "react";
import type { Adresse } from "models";
import { Button, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { Grid2Props } from "@mui/material/Grid2";
import { AdressePrincipale, ComplementsDAdresse } from "components/Adresse";
import { routesConfig } from "config/app-config";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import NotesIcon from "@mui/icons-material/Notes";
import { ChantierInfoCard } from "./ChantierInfoCard";

interface ChantierDemandeurCardProps extends Grid2Props {
  adresse: Adresse;
}

export function ChantierAdresseCard({
  adresse,
  ...gridProps
}: Readonly<ChantierDemandeurCardProps>): ReactNode {
  return (
    <Grid
      component={Paper}
      variant="outlined"
      container
      size={12}
      sx={{ p: 2, pt: 1 }}
      {...gridProps}>
      <Grid container size={12} alignItems="flex-start" sx={{ mt: 1 }}>
        <ChantierInfoCard
          Icon={HomeIcon}
          title="Adresse"
          bonus={
            <Grid sx={{ marginY: -3, ml: 1 }}>
              <Button
                href={routesConfig.modifierAdresse.getParameterPath(adresse.id)}
                size="small"
                variant="outlined"
                startIcon={<EditIcon />}>
                Modifier cette adresse
              </Button>
            </Grid>
          }>
          <AdressePrincipale adresse={adresse} displayCommuneAssocieeDeleguee />
        </ChantierInfoCard>

        <ChantierInfoCard Icon={NotesIcon} title="Complément d'adresse">
          <ComplementsDAdresse adresse={adresse} />
        </ChantierInfoCard>
      </Grid>
    </Grid>
  );
}
