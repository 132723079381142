import { Button, Grid2 as Grid, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useControleCipa } from "pages/Chantier/providers/ControleCipaProvider";
import { useCallback, type ReactNode } from "react";
import { getControleRapportStatusLabel } from "utils/labelUtil";
import { controlesService } from "services";
import { useErrorHandler } from "utils/errorHandling";
import { dateUtil } from "@sdeapps/react-core";

export function ControleCipaSummary(): ReactNode {
  const { idChantier, idDossier, getRapportHeader, getControleRapport } = useControleCipa();
  const rapport = getRapportHeader();

  const { catchErrors, isLoading: isSaving } = useErrorHandler({ defaultIsLoading: false });
  const saveRapport = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      async function postOrPutDossierControle(): Promise<void> {
        const rapport = getControleRapport();
        if (rapport == null) {
          return;
        }

        await (rapport?.id != null && rapport.id !== ""
          ? controlesService.putDossierControle(rapport, rapport.id, idChantier, idDossier)
          : controlesService.postDossierControle(rapport, idChantier, idDossier));
      }
      e.stopPropagation();
      void catchErrors(postOrPutDossierControle);
    },
    [catchErrors, getControleRapport, idChantier, idDossier]
  );

  return (
    <Grid container size="grow" alignItems="center">
      <Grid size={{ xs: 12, sm: 4 }}>
        <Typography fontWeight={500}>
          {rapport != null ? dateUtil.format(rapport.dateControle, "yyyy-MM-dd") : ""}
        </Typography>
      </Grid>

      <Grid size={{ xs: 12, sm: 4 }}>
        <Typography fontWeight={500}>
          {rapport != null && getControleRapportStatusLabel(rapport.status)}
        </Typography>
      </Grid>

      <Grid container size={{ xs: 12, sm: "grow" }} alignItems="center" justifyContent="flex-end">
        <Button
          type="submit"
          loading={isSaving}
          color="info"
          variant="contained"
          startIcon={<SaveIcon />}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={saveRapport}
          loadingPosition="start">
          Enregistrer
        </Button>
      </Grid>
    </Grid>
  );
}
