import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Avatar } from "@mui/material";
import type { SxProps } from "@mui/material";
import { useErrorHandler } from "utils/errorHandling";
import { useTechnicienAvatar } from "providers/TechnicienAvatarProvider";
import type { Technicien } from "models";

function getInitials(name?: string): string {
  return (
    name
      ?.trim()
      .split(" ")
      .map((n) => n[0])
      .join("") ?? ""
  );
}

interface TechnicienAvatarProps {
  technicien: Partial<Technicien>;
  size?: number;
  sx?: SxProps;
}

function TechnicienAvatar({
  technicien: { id, displayName },
  size,
  sx,
}: Readonly<TechnicienAvatarProps>): ReactNode {
  const [technicienPicture, setTechnicienPicture] = useState<string>();
  const { getTechnicienAvatar } = useTechnicienAvatar();

  const { catchErrors } = useErrorHandler({
    dontThrow: true,
    default: () => {
      setTechnicienPicture(undefined);
    },
  });

  useEffect(() => {
    async function getTechnicienPicture(): Promise<void> {
      const picture = await getTechnicienAvatar(id ?? "");
      setTechnicienPicture(picture);
    }
    void catchErrors(getTechnicienPicture);
    // Ajouter getTechnicienAvatar en dépendance de ce useEffect provoque des appels infinis
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catchErrors, id]);

  return (
    <Avatar
      alt={displayName}
      src={technicienPicture}
      sx={size != null ? { width: size, height: size, ...sx } : sx}>
      {getInitials(displayName)}
    </Avatar>
  );
}

export default TechnicienAvatar;
