import { useEffect } from "react";
import type { ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import { TypeDossier, TypeRejet } from "models";
import type { DossierPac, DossierPacModifyDTO } from "models";
import { useDossier } from "providers";
import { LoadingScreen } from "components/Loading";
import { FormSection } from "components/Layout";
import { grey } from "@mui/material/colors";
import { ToastMessages } from "enums";
import { enqueueSnackbar } from "notistack";
import { SdeappsError, useSnackbarErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import {
  ControlledBooleanRadio,
  ControlledNumberField,
  ControlledRadioGroup,
  ControlledStatutDossier,
  ControlledTextField,
  TechnicienAutoCompleteWithErrorBoundary,
} from "components/Inputs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { dossierService } from "services";
import patchUtils from "utils/patchUtils";
import AspectFinancierPacForm from "./AspectFinancierPacForm";
import { dossierPacSections } from "../../routes/dossiersSectionsDefinitions";
import DossierSections from "../DossierSections";
import { FormControlLabel, Radio } from "@mui/material";
import { getTypeRejetLabel } from "utils/labelUtil";

function fromDossierToDossierPacModifyDTO(dossier: DossierPac): DossierPacModifyDTO {
  const dossierPacModify: DossierPacModifyDTO = {
    idTechnicien: dossier.technicien.id,
    nameTechnicien: dossier.technicien.displayName,
    statutDemande: dossier.statutDemande,
    typeRejet: dossier.typeRejet,
    observations: dossier.observations,
    isPacAppliquee: dossier.isPacAppliquee,
    aspectFinancier: dossier.aspectFinancier,
  };

  return dossierPacModify;
}

function DossierPacFormAdministratif(): ReactNode {
  const { dossier, isLoading: isDossierLoading = false, updateDossier } = useDossier<DossierPac>();

  const formMethods = useForm<DossierPacModifyDTO>({
    shouldFocusError: false,
  });
  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods;

  const { catchErrors, isLoading } = useSnackbarErrorHandler();

  useEffect(() => {
    if (dossier != null) {
      reset(fromDossierToDossierPacModifyDTO(dossier));
    }
  }, [dossier, reset]);

  const sendData: SubmitHandler<DossierPacModifyDTO> = async function (
    dossierData: DossierPacModifyDTO
  ): Promise<void> {
    if (dossier == null) {
      throw new SdeappsError("DOSSIER NE PEUT PAS ETRE NUL !!!!");
    }
    if (!isValid) {
      console.warn("La méthode ne doit pas être appelée si le formulaire contient des erreurs.");
      return;
    }

    await catchErrors(async () => {
      const patchData = patchUtils.toPatchData(dossierData);
      await dossierService.patchDossier(dossier.idChantier, dossier.id, patchData);
      updateDossier();
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Grid
        container
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(sendData, () => {
          enqueueSnackbar({
            variant: "error",
            message: ToastMessages.ERROR_FORM_VALIDATION,
          });
        })}>
        <DossierSections isLoading={isLoading} sections={dossierPacSections} />

        {dossier == null || isLoading || isDossierLoading ? (
          <LoadingScreen />
        ) : (
          <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
            <FormSection title="Gestion de la demande">
              <Grid size={{ xs: 12, md: 6 }}>
                <Controller
                  name="idTechnicien"
                  defaultValue={dossier.technicien.id}
                  rules={{ required: "Il est obligatoire de saisir un technicien." }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TechnicienAutoCompleteWithErrorBoundary
                      libelle="Suivi par *"
                      technicienId={value}
                      error={error}
                      onChange={(tech) => {
                        onChange(tech?.id);
                        setValue("nameTechnicien", tech?.displayName);
                        setValue("mailTechnicien", tech?.mail);
                        setValue("centreTechnicien", tech?.centre);
                        setValue("telephoneTechnicien", tech?.telephone);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledStatutDossier
                  name="statutDemande"
                  label="Statut *"
                  type={TypeDossier.PAC}
                />
              </Grid>
            </FormSection>

            <FormSection title="Observations">
              <Grid size={{ xs: 12 }}>
                <ControlledRadioGroup
                  name="typeRejet"
                  label="Type rejet"
                  sx={{ display: "inline-block" }}>
                  {Object.entries(TypeRejet).map(([key, rejet]) => (
                    <FormControlLabel
                      key={key}
                      value={rejet}
                      control={<Radio />}
                      label={getTypeRejetLabel(rejet)}
                      sx={{ pr: 2 }}
                    />
                  ))}
                </ControlledRadioGroup>
              </Grid>
              <Grid size={12}>
                <ControlledTextField name="observations" label="Observations" multiline />
              </Grid>
            </FormSection>

            <FormSection title="Tarifs définis par périmètre SDEA">
              <Grid size={12}>
                <ControlledBooleanRadio
                  name="isPacAppliquee"
                  label="PAC appliquée pour ce périmètre"
                  defaultValue={true}
                  rules={{
                    validate: (value) => typeof value === "boolean" || "Ce champ est obligatoire",
                  }}
                />
              </Grid>
              <Grid size={6}>
                <ControlledNumberField
                  name="aspectFinancier.pacLogementSimple"
                  label="PAC pour un logement simple"
                  type="entier"
                  textAlign="right"
                  withEndEuro
                  rules={{ required: "Ce champ est obligatoire." }}
                />
              </Grid>
              <Grid size={6}>
                <ControlledNumberField
                  name="aspectFinancier.pacLogementSupplementaire"
                  label="PAC par logement supplémentaire"
                  type="entier"
                  textAlign="right"
                  withEndEuro
                  rules={{ required: "Ce champ est obligatoire." }}
                />
              </Grid>
            </FormSection>

            <FormSection title="Estimation de la PAC" size={12}>
              <AspectFinancierPacForm />
            </FormSection>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}

export const DossierPacFormAdministratifWithErrorBoundary = withPageErrorBoundary(
  DossierPacFormAdministratif
);
