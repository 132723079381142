import type { PropsWithChildren, ReactNode } from "react";
import type { SvgIconComponent } from "@mui/icons-material";
import { Grid2 as Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

interface ChantierInfoCardProps extends PropsWithChildren {
  Icon?: SvgIconComponent;
  title: string;
  bonus?: ReactNode;
}

export function ChantierInfoCard({
  Icon,
  title,
  bonus,
  children,
}: Readonly<ChantierInfoCardProps>): ReactNode {
  return (
    <Grid container size={{ xs: 12, md: "grow" }} spacing={1}>
      <Grid container alignItems="center" spacing={1} size={12}>
        {Icon != null && <Icon sx={{ color: grey[500] }} />}
        <Typography variant="subtitle2">{title}</Typography>
        {bonus}
      </Grid>
      <Grid size={12}>{children}</Grid>
    </Grid>
  );
}
