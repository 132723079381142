import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useEffect, useRef, useState } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { useReactToPrint } from "react-to-print";
import type { TypeRejet, DocumentDataCadreDto, DossierAss, DossierCipa, DossierEau } from "models";
import { getTypeRejetLabel } from "utils/labelUtil";
import { courriersService } from "services";

interface InfoRowProps extends PropsWithChildren {
  label?: string;
  value?: string;
}
function InfoRow({ label, value, children }: Readonly<InfoRowProps>): ReactNode {
  return (
    <Grid size={12} container alignItems="baseline" columnSpacing={2}>
      <Grid size={value != null && value.length > 0 ? 4 : 12}>
        <Typography variant="h6">{label}</Typography>
      </Grid>
      {children ?? (
        <Grid size={label != null && label.length > 0 ? 8 : 12}>
          <Typography>{value}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

function InfoChildRow({ label, value }: Readonly<InfoRowProps>): ReactNode {
  return (
    <Grid size={12} container alignItems="baseline" columnSpacing={2}>
      <Grid size={4}>
        {label != null && label.length > 0 && <Typography textAlign="right">{label} :</Typography>}
      </Grid>
      <Grid size={8}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
}

interface DocumentCadreButtonProps {
  dossier: DossierAss | DossierEau | DossierCipa;
}
export function DocumentCadreButton({ dossier }: Readonly<DocumentCadreButtonProps>): ReactNode {
  const [dataCadre, setDataCadre] = useState<DocumentDataCadreDto>();
  useEffect(() => {
    async function loadCourrierData(): Promise<void> {
      const data = await courriersService.getCourrierData("Cadre", dossier);
      setDataCadre(data);
    }
    void loadCourrierData();
  }, [dossier]);
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    documentTitle: `Cadre ${dataCadre?.dossier?.referenceCipa}`,
    contentRef,
  });

  return (
    <Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          reactToPrintFn();
        }}
        startIcon={<LocalPrintshopIcon color="primary" />}>
        Cadre
      </Button>
      {dataCadre != null && (
        <Box
          ref={contentRef}
          sx={{
            p: 6,
            display: "none",
            "@media print": {
              display: "block",
              pt: 10,
            },
          }}>
          <Grid container spacing={4}>
            <Grid size="auto" alignContent="center">
              <Box
                sx={{
                  writingMode: "vertical-lr",
                  transform: "rotate(180deg)",
                  float: "left",
                  mr: 3,
                }}>
                <Typography variant="h2">
                  N° Dossier : {dataCadre.dossier?.referenceCipa}
                </Typography>
              </Box>
            </Grid>
            <Grid container size="grow" rowSpacing={1}>
              <InfoRow
                label="Type de rejet"
                value={getTypeRejetLabel(dataCadre.dossier?.typeRejet as TypeRejet)}
              />
              <InfoRow label="Affaire suivie par" value={dataCadre.dossier?.technicienName} />
              <InfoRow label="Adresse chantier">
                <InfoChildRow label="Commune" value={dataCadre.adresse?.libelleCommune} />
                <InfoChildRow
                  label="Propriétaire"
                  value={dataCadre.demandeur1?.civiliteNomPrenom}
                />
                {dataCadre.demandeur2 != null && (
                  <>
                    <InfoChildRow label="" value={dataCadre.demandeur2?.civiliteNomPrenom} />
                    <InfoChildRow label="Adresse" value={dataCadre.adresse?.adresseVoie} />
                  </>
                )}
              </InfoRow>
              <InfoRow label="Adresse actuelle">
                <InfoChildRow label="Adresse" value={dataCadre.demandeur1?.adresse?.adresseVoie} />
                <InfoChildRow
                  label="Commune"
                  value={dataCadre.demandeur1?.adresse?.libelleCommune}
                />
                <InfoChildRow
                  label="Code postal"
                  value={dataCadre.demandeur1?.adresse?.codePostal}
                />
              </InfoRow>
              <InfoRow label="Observations">
                <Typography>{dataCadre.dossier?.observations}</Typography>
              </InfoRow>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
