import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  ListItemAvatar,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import type { SxProps } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BarChartIcon from "@mui/icons-material/BarChart";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import GridViewIcon from "@mui/icons-material/GridView";
import LastPageIcon from "@mui/icons-material/LastPage";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import type { ReactNode } from "react";
import { envConfig, routesConfig } from "config/app-config";
import { Link } from "react-router-dom";
import { useCurrentPathRoute, useMenu } from "hooks";
import { authenticationService, Authorization, useUser } from "@sdeapps/react-core";
import NavigationDrawer from "./NavigationDrawer";
import { ApplicationRole } from "constants/ApplicationRole";

const listItemButtonStyle: SxProps = {
  borderRadius: 1,
  "&:hover": { backgroundColor: "primary.light" },
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "primary.main",
    color: "white",
  },
  "&.Mui-selected .MuiTypography-root": { color: "white" },
};

const ENV_NAME: string =
  (envConfig.name ?? "").length > 0 && envConfig.name.toLocaleLowerCase() !== "prod"
    ? envConfig.name.toLocaleUpperCase()
    : "";

function getInitials(name: string): string {
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toLocaleUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toLocaleUpperCase();
  }
  return initials;
}

function logout(): void {
  void authenticationService.logout();
}

type NavigationEntry = {
  libelle: string;
  icon: ReactNode;
  path?: string;
  roles?: Array<string> | string;
};
const actions: Array<NavigationEntry> = [
  { libelle: "Nouveau chantier", icon: <AddIcon />, path: routesConfig.nouveauChantier.path },
];

const menus: Array<NavigationEntry> = [
  { libelle: "Dashboard", icon: <GridViewIcon /> },
  { libelle: "Recherche", icon: <SearchIcon />, path: routesConfig.home.path },
  { libelle: "Clients", icon: <PersonIcon /> },
  { libelle: "Adresses", icon: <LocationCityIcon />, path: routesConfig.adresses.path },
  { libelle: "Statistiques", icon: <BarChartIcon /> },
  { libelle: "Avis", icon: <SearchIcon />, path: routesConfig.avis.path },
  {
    libelle: "Administration",
    icon: <AdminPanelSettingsIcon />,
    path: routesConfig.administration.path,
    roles: ApplicationRole.ADMINISTRATOR,
  },
];

interface MenuEntryProps {
  navigationEntry: NavigationEntry;
  isOpen: boolean;
  currentPath?: string;
}

function MenuEntry({ navigationEntry, isOpen, currentPath }: Readonly<MenuEntryProps>): ReactNode {
  const { libelle, icon, path } = navigationEntry;
  return (
    <ListItem disablePadding>
      <Tooltip title={libelle} placement="right" disableHoverListener={isOpen}>
        <ListItemButton
          selected={currentPath === path}
          disabled={path == null}
          component={path != null ? Link : "div"}
          to={path}
          sx={listItemButtonStyle}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isOpen ? 3 : "auto",
              color: "inherit",
            }}>
            {icon}
          </ListItemIcon>
          <ListItemText primary={libelle} sx={{ opacity: isOpen ? 1 : 0 }} />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
}

interface NavigationMenuProps {
  isOpen: boolean;
  onOpen: (v: boolean) => void;
}

function NavigationMenu({ isOpen = false, onOpen }: Readonly<NavigationMenuProps>): ReactNode {
  const { user } = useUser();
  const currentPath = useCurrentPathRoute();
  function handleDrawer(): void {
    onOpen(!isOpen);
  }
  const { menuAnchor, isOpen: isMenuOpen, handleOpen, handleClose } = useMenu();

  return (
    <NavigationDrawer variant="permanent" className={isOpen ? "open" : undefined} open={isOpen}>
      <Typography variant="h1" textAlign="center" marginBottom=".25em" marginTop=".25em">
        {isOpen ? "Opale" : "O."}
        {isOpen && ENV_NAME !== "" && (
          <span style={{ fontSize: "1rem", marginLeft: "1rem" }}>{ENV_NAME}</span>
        )}
      </Typography>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
        }}>
        {actions.map(({ libelle, icon, path }) => (
          <ListItem key={libelle} disablePadding sx={{ display: "block" }}>
            <Tooltip title={libelle} placement="right" disableHoverListener={isOpen}>
              <ListItemButton
                selected={currentPath === path}
                component={path != null ? Link : "div"}
                to={path}
                sx={listItemButtonStyle}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : "auto",
                    color: "inherit",
                  }}>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={libelle} sx={{ opacity: isOpen ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
        <ListItem
          secondaryAction={
            <Tooltip title={isOpen ? "Réduire" : "Agrandir"} placement="right" enterDelay={600}>
              <IconButton onClick={handleDrawer} sx={{ mr: -0.5 }}>
                {isOpen ? <FirstPageIcon /> : <LastPageIcon />}
              </IconButton>
            </Tooltip>
          }>
          <ListItemText primary="Menu" sx={{ opacity: isOpen ? 1 : 0 }} />
        </ListItem>
        <Divider />
        {menus.map((navigationEntry) =>
          navigationEntry.roles !== undefined && navigationEntry.roles?.length > 0 ? (
            <Authorization key={navigationEntry.libelle} roles={navigationEntry.roles}>
              <MenuEntry
                navigationEntry={navigationEntry}
                isOpen={isOpen}
                currentPath={currentPath}
              />
            </Authorization>
          ) : (
            <MenuEntry
              key={navigationEntry.libelle}
              navigationEntry={navigationEntry}
              isOpen={isOpen}
              currentPath={currentPath}
            />
          )
        )}
        <ListItem sx={{ flex: "1 1 auto" }} />
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton onClick={handleOpen}>
            <ListItemAvatar sx={{ ml: -0.5 }}>
              <Avatar alt={user.displayName} src={user.avatar}>
                {getInitials(user.displayName)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography whiteSpace="normal" minWidth="130px">
                  {user.displayName}
                </Typography>
              }
              sx={{ opacity: isOpen ? 1 : 0 }}
            />
          </ListItemButton>
          <Menu
            anchorEl={menuAnchor}
            id="account-menu"
            open={isMenuOpen}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}>
            <MenuItem onClick={logout}>
              <LogoutIcon color="error" sx={{ mr: 1 }} />
              Déconnexion
            </MenuItem>
          </Menu>
        </ListItem>
      </List>
    </NavigationDrawer>
  );
}

export default NavigationMenu;
