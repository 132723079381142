/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ControleDetailStatus, ControleRapportStatus } from "models";
import type {
  ControleDefinition,
  ControleDetailWithDefinition,
  ControleRapport,
  ControleRapportWithoutDetails,
} from "models";
import type { PropsWithChildren, ReactNode } from "react";
import { controlesService } from "services";
import { useErrorHandler } from "utils/errorHandling";
import { dateUtil } from "@sdeapps/react-core";

export interface ControleCipaContextData {
  isWorking: boolean;
  idChantier: string;
  idDossier: string;
  controlesDefinition: Array<ControleDefinition>;
  getDetailByDefinitionId: (definitionId: string) => ControleDetailWithDefinition | undefined;
  updateControleDetail: (controle: ControleDetailWithDefinition) => void;
  getRapportHeader: () => ControleRapportWithoutDetails | undefined;
  updateRapportHeader: (rapport: ControleRapportWithoutDetails) => void;
  getControleRapport: () => ControleRapport | undefined;
}

const ControleContextDefaultData: ControleCipaContextData = {
  isWorking: false,
  idChantier: "",
  idDossier: "",
  controlesDefinition: [],
  getDetailByDefinitionId: (_) => undefined,
  updateControleDetail: (_) => {},
  getRapportHeader: () => undefined,
  updateRapportHeader: (_) => {},
  getControleRapport: () => undefined,
};

const ControleContext = createContext(ControleContextDefaultData);

/** Converti une hierachie de définition en sa vue à plat, triée par ordre */
function flatDefinitionsAndSort(definitions: Array<ControleDefinition>): Array<ControleDefinition> {
  function flatDefinitions(definitions: Array<ControleDefinition>): Array<ControleDefinition> {
    return definitions.flatMap((item) => {
      return [
        item,
        ...(item.children != null && item.children.length > 0
          ? flatDefinitions(item.children)
          : []),
      ];
    });
  }
  return flatDefinitions(definitions).sort((a, b) => a.ordre - b.ordre);
}

interface ControleCipaProviderProps extends PropsWithChildren {
  idChantier: string;
  idDossier: string;
  rapport?: ControleRapport;
  version?: number;
}

export function ControleCipaProvider({
  idChantier,
  idDossier,
  rapport,
  version = 0,
  children,
}: Readonly<ControleCipaProviderProps>): ReactNode {
  const [currentVersion, setCurrentVersion] = useState<number>(version);
  const [controlesDefinition, setControlesDefinition] = useState<Array<ControleDefinition>>([]);
  const [localRapport, setLocalRapport] = useState<ControleRapportWithoutDetails | undefined>(
    rapport
  );
  const [localDetails, setLocalDetails] = useState<Map<string, ControleDetailWithDefinition>>(
    new Map()
  );

  const { catchErrors: catchErrorsLoadDefinition, isLoading: isWorking } = useErrorHandler({
    defaultIsLoading: false,
  });
  useEffect(() => {
    async function getControleDefinition(): Promise<void> {
      const definition = await controlesService.getDefinition(version);
      setControlesDefinition(definition);
      const definitionVersion =
        definition != null && definition.length > 0 ? definition[0].version : 0;
      setCurrentVersion(definitionVersion);
    }
    void catchErrorsLoadDefinition(getControleDefinition);
  }, [catchErrorsLoadDefinition, version]);

  useEffect(() => {
    if (controlesDefinition.length === 0) {
      return;
    }
    const flatDefinitions = flatDefinitionsAndSort(controlesDefinition);
    const existingDetails = rapport?.details ?? [];
    const flatDetails = flatDefinitions.reduce<Map<string, ControleDetailWithDefinition>>(
      (acc, definition) => {
        const detail = existingDetails.find((d) => d.definitionId === definition.id) ?? {};
        acc.set(definition.id, {
          ...detail,
          definitionId: definition.id,
          definition,
        });
        return acc;
      },
      new Map()
    );
    const rapportToStore: ControleRapport = rapport ?? {
      id: "",
      version: currentVersion,
      dateControle: dateUtil.format(new Date(), "yyyy-MM-dd"),
      status: ControleRapportStatus.NonConforme,
      details: [],
    };
    rapportToStore.dossierCipaId = idDossier;
    if (rapportToStore.version < 0) {
      rapportToStore.version = currentVersion;
    }
    setLocalRapport(rapportToStore);
    setLocalDetails(flatDetails);
  }, [rapport, controlesDefinition, currentVersion, idDossier]);

  const updateRapportHeader = useCallback((rapport: ControleRapportWithoutDetails) => {
    setLocalRapport(rapport);
  }, []);
  const getRapportHeader = useCallback(() => {
    return localRapport;
  }, [localRapport]);

  const getDetailByDefinitionId = useCallback(
    (definitionId: string) => {
      return localDetails.get(definitionId);
    },
    [localDetails]
  );

  const updateControleDetail = useCallback(
    (controle: ControleDetailWithDefinition) => {
      const newDetails = new Map(localDetails);
      newDetails.set(controle.definitionId, controle);
      setLocalDetails(newDetails);
    },
    [localDetails]
  );

  const getControleRapport = useCallback((): ControleRapport | undefined => {
    if (localRapport != null && localDetails.size > 0) {
      // Ne retourne que les détails ayant un statut ou une saisie utilisateur
      const detailsWithUserData = [...localDetails.values()].filter(
        (d) =>
          d.status != null ||
          (d.userInput != null && d.userInput !== "") ||
          (d.userComment != null && d.userComment !== "")
      );
      // Filtre ces détails pour ne retourner que ceux dont le parent n'est pas avec le statut "Non concerné"
      // c'est à dire ceux ayant ce statut mais qui ne sont pas du niveau "PointControle" et qui ont des enfants
      const filteredDetails = detailsWithUserData
        .filter(
          (d) =>
            d.status === ControleDetailStatus.NonConcerne &&
            d.definition.niveau !== "PointControle" &&
            d.definition.children != null &&
            d.definition.children.length > 0
        )
        .map(({ definitionId }) => definitionId)
        .reduce((filteringDetails, definitionId) => {
          return filteringDetails.filter((d) => d.definition.parentId !== definitionId);
        }, detailsWithUserData);

      const rapport: ControleRapport = {
        ...localRapport,
        details: filteredDetails,
      };

      return rapport;
    }
    return undefined;
  }, [localRapport, localDetails]);

  const data: ControleCipaContextData = useMemo(() => {
    return {
      isWorking,
      idChantier,
      idDossier,
      controlesDefinition,
      getControleRapport,
      getDetailByDefinitionId,
      getRapportHeader,
      updateRapportHeader,
      updateControleDetail,
    };
  }, [
    isWorking,
    idChantier,
    idDossier,
    controlesDefinition,
    getDetailByDefinitionId,
    updateControleDetail,
    getRapportHeader,
    updateRapportHeader,
    getControleRapport,
  ]);

  return <ControleContext.Provider value={data}>{children}</ControleContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useControleCipa(): ControleCipaContextData {
  const context = useContext(ControleContext);

  if (context == null) {
    throw new Error(`${useControleCipa.name} must be used within a ${ControleCipaProvider.name}`);
  }

  return context;
}
