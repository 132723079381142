import type { SxProps } from "@mui/material";
import { ControleDetailStatusValues } from "models";
import type { ControleDefinitionLevel } from "models";

/**
 * Nombre magique utilisée pour le colonage des statuts de contrôles.
 */
const radioColumnCountOnSmallDevice = 3;

/**
 * Retourne une clé/valeur définissant une variable CSS utilisée pour le colonnage des status des contrôles.
 * @param useInlineLayout Indique le mode de rendu, lorsque `true` limite la largeur, sinon utilise une répartition équilibrée.
 */
export function getStatusCellWidthVariable(useInlineLayout: boolean): SxProps {
  return { "--status-cell-width": useInlineLayout ? "70px" : "1fr" };
}

/**
 * Retourn le style applicable à un titre selon son niveau de définition du contrôle.
 * @param niveau Niveau du contrôle selon la définition du point correspondant.
 */
export function getSxByNiveau(niveau: ControleDefinitionLevel): SxProps | undefined {
  switch (niveau) {
    case "Chapitre":
      return { fontSize: "1.1em", fontWeight: 900 };
    case "Section":
      return { fontSize: "1.05em", fontWeight: 800 };
    case "SousSection":
      return { fontSize: "1.025em", fontWeight: 700, fontStyle: "italic" };
    case "PointControle":
      return { fontSize: "initial", fontWeight: 500 };
    default:
      return undefined;
  }
}

/**
 * Retourne le style `SxProps` définissant une grid CSS afin d'aligner les headers et les boutons radios des statuts des contrôles.
 * @param useInlineLayout Indique le mode de rendu, lorsque `true` utilise toute la largeur, sinon limite le nombre de colonnes.
 */
export function getStatusGridLayout(useInlineLayout: boolean): SxProps {
  return {
    display: "grid",
    width: "100%",
    backgroundColor: "white",
    gridTemplateColumns: `repeat(${
      useInlineLayout ? ControleDetailStatusValues.length : radioColumnCountOnSmallDevice
    }, var(--status-cell-width))`,
    gridTemplateRows: `repeat(1, 1fr)`,
    gap: 1,
  };
}

/**
 * Retourne la chaine de caractères d'une position dans une grid CSS d'un élément selon son index assignable à la propriété CSS `grid-area`.
 * @param index Index de l'élément à positionner dans une grid CSS définie via la méthode `getStatusGridLayout(useInlineLayout)`.
 * @param useInlineLayout Indique le mode de rendu, lorsque `true` utilise toute la largeur, sinon limite le nombre de colonnes.
 */
export function getGridAreaFromIndex(index: number, useInlineLayout: boolean): string {
  const row = useInlineLayout ? 1 : Math.floor(index / radioColumnCountOnSmallDevice) + 1;
  const col = useInlineLayout ? index + 1 : index % radioColumnCountOnSmallDevice;
  return `${row} / ${col} / ${row} / ${col}`;
}
