import { FormControl, FormHelperText, FormLabel, RadioGroup } from "@mui/material";
import type { SxProps } from "@mui/material";
import { type ReactNode } from "react";
import { useController, type FieldPath, type FieldValues } from "react-hook-form";
import { type ControlledInput } from "utils/rules";

interface ControlledRadioGroupProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label?: string;
  children?: ReactNode;
  /** SxProps appliquée au `<FormControl />` root */
  sx?: SxProps;
}

export function ControlledRadioGroup<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  label,
  defaultValue,
  rules,
  children,
  sx,
}: Readonly<ControlledRadioGroupProps<T, Name>>): ReactNode {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });
  return (
    <FormControl fullWidth sx={sx}>
      {label != null && <FormLabel sx={{ display: "inline", pr: 2 }}>{label}</FormLabel>}
      <RadioGroup
        value={value ?? null}
        sx={{ display: "inline-block" }}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        defaultValue={defaultValue}>
        {children}
      </RadioGroup>
      <FormHelperText error={error != null}>{error?.message}</FormHelperText>
    </FormControl>
  );
}
