import type { ReactNode } from "react";
import type { Demandeur } from "models";
import { getDemandeurDisplayName } from "utils/demandeurUtil";

interface DemandeurSectionProps {
  demandeur?: Demandeur;
}

function DemandeurSection({ demandeur }: Readonly<DemandeurSectionProps>): ReactNode {
  if (demandeur == null) {
    return null;
  }
  return (
    <>
      {getDemandeurDisplayName(demandeur)} <br />
      {demandeur.adresseNumero}&nbsp;{demandeur.adresseRue} <br />
      {demandeur.adresseCodePostal} {demandeur.adresseCommune}
    </>
  );
}

export default DemandeurSection;
