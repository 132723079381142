import { useCallback, useMemo, useState } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { ChantiersSearchContext } from "../contexts/ChantiersSearchContext";
import type { ChantiersSearchData } from "../contexts/ChantiersSearchContext";
import { AllStatutDossier } from "models";
import type { Chantier, ChantiersSearchFiltre, PaginatedQuery, PaginatedResult } from "models";
import { chantierService } from "services";
import { useErrorHandler } from "utils/errorHandling";
import { getDossiers } from "utils/dossierUtil";
import { useUser } from "@sdeapps/react-core";

function concatChantiersResponseAndCountDossiers(
  existingChantiers: Array<Chantier>,
  newChantiers: Array<Chantier>
): Array<Chantier> {
  let mergedResult: Array<Chantier> = newChantiers;
  const currentChantiers = [...existingChantiers];
  const lastChantierId = currentChantiers.at(-1)?.id;
  const firstNewChantierId = mergedResult.at(0)?.id;
  if (currentChantiers.length > 0 && lastChantierId === firstNewChantierId) {
    // extraction/splice du 1er de chantiers de la page suivante
    const toMerge = mergedResult.splice(0, 1)[0];
    // merge avec le dernier de paginatedChantiers des précédents résultats
    const toMergeTo = currentChantiers.splice(-1, 1)[0];
    // fusion des chantiers des 2 set de résultats
    const newOne: Chantier = {
      ...toMergeTo,
      ...toMerge,
    };
    mergedResult = [...currentChantiers, newOne, ...mergedResult];
  } else {
    mergedResult = [...currentChantiers, ...mergedResult];
  }
  return mergedResult;
}

function countDossiersInChantiers(chantiers: Array<Chantier>): number {
  return chantiers.reduce((acc, chantier) => {
    return acc + getDossiers(chantier).length;
  }, 0);
}

function getChantiersFromResponse(response: PaginatedResult<Chantier>): Array<Chantier> {
  return (
    response.result
      // On filtre pour retirer les chantiers de la recherche qui n'ont pas de dossiers
      .map((chantier) => {
        if (getDossiers(chantier).length === 0) {
          return undefined;
        }
        return chantier;
      })
      .filter(Boolean) as Array<Chantier>
  );
}

export function ChantiersSearchProvider({ children }: Readonly<PropsWithChildren>): ReactNode {
  const { catchErrors, isLoading } = useErrorHandler();
  const [chantiers, setChantiers] = useState<Array<Chantier>>([]);
  const { user } = useUser();
  const defaultFiltres: ChantiersSearchFiltre = useMemo(() => {
    const _defaultFiltres: ChantiersSearchFiltre = {
      client: "",
      adresse: "",
      communeId: "",
      reference: "",
      type: undefined,
      dateDemandeAuIncluse: undefined,
      dateDemandeDuIncluse: undefined,
      technicienId: user.id,
      statuts: [
        AllStatutDossier.EN_COURS_TRAITEMENT,
        AllStatutDossier.INCOMPLET,
        AllStatutDossier.ATTENTE_REALISATION_TRAVAUX,
        AllStatutDossier.ATTENTE_DOCUMENTS,
        AllStatutDossier.TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR,
        AllStatutDossier.ANNULE,
        AllStatutDossier.ERREUR_DEMANDE,
        AllStatutDossier.AUTRE,
        AllStatutDossier.PROBLEME_TECHNIQUE,
      ],
    };
    return _defaultFiltres;
  }, [user.id]);
  const [filtres, setFiltres] = useState<ChantiersSearchFiltre>(defaultFiltres);
  const [pagination, setPagination] = useState<PaginatedQuery>({ offset: 0, size: 10 });
  const [hasMoreResult, setHasMoreResult] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const updateFiltres = useCallback(
    (newFiltres: ChantiersSearchFiltre): void => {
      void catchErrors(async () => {
        const _filtres = newFiltres;
        const _pagination = { offset: 0, size: 10 };

        const response = await chantierService.getAllChantiers({ ..._pagination, ..._filtres });
        const _chantiers = getChantiersFromResponse(response);
        const dossiersTotalCount = countDossiersInChantiers(_chantiers);
        const _hasMoreResult = dossiersTotalCount < response.totalCount;

        setFiltres(_filtres);
        setPagination(_pagination);
        setChantiers(_chantiers);
        setTotalCount(response.totalCount);
        setHasMoreResult(_hasMoreResult);
      });
    },
    [catchErrors]
  );
  const loadNextPage = useCallback(() => {
    void catchErrors(async () => {
      const _filtres = filtres;
      const _pagination = {
        offset: pagination.offset + pagination.size,
        size: pagination.size,
      };

      const response = await chantierService.getAllChantiers({ ..._pagination, ..._filtres });
      const _chantiers = getChantiersFromResponse(response);
      const resultChantiers =
        _pagination.offset > 0
          ? concatChantiersResponseAndCountDossiers(chantiers, _chantiers)
          : _chantiers;
      const dossiersTotalCount = countDossiersInChantiers(resultChantiers);
      const _hasMoreResult = dossiersTotalCount < response.totalCount;

      setPagination(_pagination);
      setChantiers(resultChantiers);
      setTotalCount(response.totalCount);
      setHasMoreResult(_hasMoreResult);
    });
  }, [catchErrors, chantiers, filtres, pagination]);

  const data: ChantiersSearchData = useMemo(() => {
    return {
      isLoading,
      pagination,
      paginatedChantiers: {
        totalCount,
        result: chantiers,
      },
      hasMoreResult,
      filtres,
      defaultFiltres,
      updateFiltres,
      loadNextPage,
    };
  }, [
    isLoading,
    pagination,
    totalCount,
    chantiers,
    hasMoreResult,
    filtres,
    defaultFiltres,
    updateFiltres,
    loadNextPage,
  ]);

  return <ChantiersSearchContext.Provider value={data}>{children}</ChantiersSearchContext.Provider>;
}
