import type { Avis, PaginatedQueryAvis, PaginatedResult } from "models";
import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";

/**
 * Retourne un Avis à partir de son id
 * @returns Un Avis
 */
async function getAvisById(id: string): Promise<Avis> {
  const url = `/${Endpoints.AVIS}/${id}`;
  return opaleApiClient.get<Avis, Avis>(url);
}

/**
 * Retourne tous les Avis
 * @returns Un tableau d'Avis
 */
async function getAllAvis(filtre: PaginatedQueryAvis): Promise<PaginatedResult<Avis>> {
  let url = `/${Endpoints.AVIS}`;
  if (filtre != null && Object.values(filtre).filter((v) => v != null && v !== "").length > 0) {
    const filtreNotNull = Object.entries(filtre)
      .filter(([_, value]) =>
        Array.isArray(value) ? value.length > 0 : value != null && value !== ""
      )
      .reduce<Record<string, string>>((acc, [key, value]) => {
        if (Array.isArray(value)) {
          acc[key] = value.join(",");
        } else {
          acc[key] = String(value);
        }
        return acc;
      }, {});
    url += `?${new URLSearchParams(filtreNotNull)}`;
  } else {
    url = `/${Endpoints.AVIS}?offset=0&size=10`;
  }
  return opaleApiClient.get<PaginatedResult<Avis>, PaginatedResult<Avis>>(url);
}

export const avisService = {
  getAvisById,
  getAllAvis,
};
