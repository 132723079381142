import {
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { ControleDetailStatus, ControleDetailStatusValues } from "models";
import type { ControleDefinition, ControleDetailWithDefinition } from "models";
import { useCallback, useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useControleCipa } from "pages/Chantier/providers/ControleCipaProvider";
import { useDebouncedCallback } from "use-debounce";
import { getControleDetailStatusLabel } from "utils/labelUtil";
import {
  getGridAreaFromIndex,
  getStatusCellWidthVariable,
  getStatusGridLayout,
  getSxByNiveau,
} from "./ControleUtils";

interface ControleCipaDetailLineProps {
  controleDefinition: ControleDefinition;
  useInlineLayout: boolean;
}
export function ControleCipaDetailLine({
  controleDefinition,
  useInlineLayout,
}: Readonly<ControleCipaDetailLineProps>): ReactNode {
  const { getDetailByDefinitionId, updateControleDetail } = useControleCipa();
  const currentDetail = getDetailByDefinitionId(controleDefinition.id);
  const updateControleProperty = useCallback(
    (propToUpdate: Partial<ControleDetailWithDefinition>) => {
      if (currentDetail != null) {
        updateControleDetail({
          ...currentDetail,
          ...propToUpdate,
        });
      }
    },
    [currentDetail, updateControleDetail]
  );

  const updateControlePropertyDebounced = useDebouncedCallback(updateControleProperty, 250);
  /**
   * Cas spécifique pour l'input de type texte, afin de permettre une expérience fluide sans (ou très peu)
   * de lag cette valeur est également contrôlée via un state local, puis sera dispatch au contextProvider
   * via un appel debounced avec `updateControlePropertyDebounced`, le state local et cet appel seront gérés depuis
   * le callback `onUserInputChange`.
   */
  const [userInput, setUserInput] = useState(currentDetail?.userInput ?? "");
  const onUserInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const userInput = e.target.value;
      setUserInput(userInput);
      updateControlePropertyDebounced({ userInput });
    },
    [updateControlePropertyDebounced]
  );

  const [userCommentInput, setUserCommentInput] = useState(currentDetail?.userComment ?? "");

  const onUserCommentInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const userComment = e.target.value;
      setUserCommentInput(userComment);
      updateControlePropertyDebounced({ userComment });
    },
    [updateControlePropertyDebounced]
  );

  useEffect(() => {
    setUserInput(currentDetail?.userInput ?? "");
    setUserCommentInput(currentDetail?.userComment ?? "");
  }, [currentDetail]);

  return (
    <Grid
      size={12}
      container
      key={controleDefinition.id}
      sx={{ pl: useInlineLayout ? 2 : 0, alignItems: "center" }}>
      <Grid size={useInlineLayout ? "grow" : 12}>
        <Typography sx={getSxByNiveau(controleDefinition.niveau)}>
          {controleDefinition.libelle}
        </Typography>
      </Grid>

      {controleDefinition.hasUserInput && (
        <Grid size={useInlineLayout ? 2 : 12} sx={{ paddingLeft: 3 }}>
          <TextField size="small" value={userInput} onChange={onUserInputChange} />
        </Grid>
      )}

      <Grid
        container
        size={useInlineLayout ? "auto" : 12}
        direction="column"
        sx={{
          alignItems: "flex-end",
          ...getStatusCellWidthVariable(useInlineLayout),
        }}>
        {controleDefinition.niveau === "PointControle" ? (
          <RadioGroup
            row
            value={currentDetail?.status ?? ""}
            onChange={(e) => {
              updateControleProperty({ status: e.target.value as ControleDetailStatus });
            }}
            sx={getStatusGridLayout(useInlineLayout)}>
            {ControleDetailStatusValues.map((status, index) => (
              <FormControlLabel
                key={status}
                sx={{
                  gridArea: getGridAreaFromIndex(index, useInlineLayout),
                  padding: 0,
                  margin: 0,
                  textAlign: "center",
                  whiteSpace: "break-spaces",
                }}
                label={
                  useInlineLayout ? undefined : (
                    <Typography variant="body2">{getControleDetailStatusLabel(status)}</Typography>
                  )
                }
                labelPlacement="bottom"
                value={status}
                control={
                  <Radio size="small" sx={{ "& .MuiSvgIcon-root": { fontSize: "inherit" } }} />
                }
              />
            ))}
          </RadioGroup>
        ) : (
          <FormControlLabel
            label={
              useInlineLayout
                ? undefined
                : getControleDetailStatusLabel(ControleDetailStatus.NonConcerne)
            }
            labelPlacement="start"
            control={
              <Checkbox
                size="small"
                checked={currentDetail?.status === ControleDetailStatus.NonConcerne}
                onChange={(_, checked) => {
                  updateControleProperty({
                    status: checked ? ControleDetailStatus.NonConcerne : undefined,
                  });
                }}
              />
            }
            sx={{
              paddingRight: 3.25,
              textAlign: "center",
              wordBreak: "break-all",
            }}
          />
        )}
      </Grid>

      {currentDetail?.status !== ControleDetailStatus.NonConcerne &&
        controleDefinition.children != null &&
        controleDefinition.children.length > 0 &&
        controleDefinition.children.map((controleDefinitionEnfant) => (
          <ControleCipaDetailLine
            key={controleDefinitionEnfant.id}
            useInlineLayout={useInlineLayout}
            controleDefinition={controleDefinitionEnfant}
          />
        ))}
      {currentDetail?.status !== ControleDetailStatus.NonConcerne &&
        controleDefinition.hasUserComment && (
          <Grid size={12} sx={{ mb: 1 }}>
            <TextField
              placeholder="Commentaires"
              fullWidth
              size="small"
              value={userCommentInput}
              onChange={onUserCommentInputChange}
            />
          </Grid>
        )}
    </Grid>
  );
}
