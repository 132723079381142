import { Divider, LinearProgress, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ControlledNumberField, ControlledTextField } from "components/Inputs";
import type { DevisEauPatchDto } from "models";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { calculService } from "services";
import { useDebouncedCallback } from "use-debounce";
import { useSnackbarErrorHandler } from "utils/errorHandling";
import {
  ACOMPTE_OPTIONS,
  FRAIS_DE_DOSSIER_OPTIONS,
  FRAIS_MO_OPTIONS,
  TVA_OPTIONS,
} from "../../data/aspectFinancierOptions";
import {
  AspectFinancierComplexLine,
  AspectFinancierSimpleLine,
  MontantTypography,
} from "../AspectFinancier";
import DividerGrid from "../AspectFinancier/DividerGrid";

function AspectFinancierEauForm(): ReactNode {
  const { setValue } = useFormContext<DevisEauPatchDto>();
  const { aspectFinancier } = useWatch<DevisEauPatchDto>();

  const { catchErrors: catchErrorsCalcul, isLoading: isLoadingCalcul } = useSnackbarErrorHandler();

  const debouncedCalcul = useDebouncedCallback(() => {
    void catchErrorsCalcul(async () => {
      if (aspectFinancier != null) {
        const {
          version,
          montantFraisMo,
          montantFraisDeDossier,
          totalHt,
          totalTtc,
          montantTva,
          montantAcompteHt,
          montantAcompteTtc,
          montantTotalTtc,
          ...param
        } = aspectFinancier;

        if (param != null) {
          const res = await calculService.postCalculEau(param);
          setValue("aspectFinancier.montantFraisMo", res.montantFraisMo);
          setValue("aspectFinancier.montantFraisDeDossier", res.montantFraisDeDossier);
          setValue("aspectFinancier.totalHt", res.totalHt);
          setValue("aspectFinancier.totalTtc", res.totalTtc);
          setValue("aspectFinancier.montantTva", res.montantTva);
          setValue("aspectFinancier.montantAcompteHt", res.montantAcompteHt);
          setValue("aspectFinancier.montantAcompteTtc", res.montantAcompteTtc);
          setValue("aspectFinancier.montantTotalTtc", res.montantTotalTtc);
        }
      }
    });
  }, 500);

  useEffect(debouncedCalcul, [
    debouncedCalcul,
    aspectFinancier?.montantDevis,
    aspectFinancier?.fraisMo,
    aspectFinancier?.fraisDeDossier,
    aspectFinancier?.acompte,
    aspectFinancier?.nombreAcces,
    aspectFinancier?.fraisAcces,
    aspectFinancier?.tva,
  ]);

  return (
    <Grid container size={12} sx={{ justifyContent: "center" }}>
      <Grid size={12}>
        <ControlledTextField name="aspectFinancier.observations" label="Observations" multiline />
      </Grid>
      <Divider orientation="horizontal" flexItem sx={{ width: "90%", margin: "30px 0 10px 0" }} />
      <Grid
        container
        size={{ xs: 12, md: 10, lg: 12 }}
        spacing={2}
        columnSpacing={3}
        sx={{ alignItems: "start" }}>
        <Grid sx={{ height: "4px" }} size={12}>
          {isLoadingCalcul && <LinearProgress />}
        </Grid>

        <Grid container size={{ xs: 12, lg: 5.7 }} columnSpacing={2}>
          <AspectFinancierSimpleLine title="Montant devis HT" heavyTitle>
            <ControlledNumberField
              size="small"
              name="aspectFinancier.montantDevis"
              type="decimal"
              withEndEuro
              textAlign="right"
            />
          </AspectFinancierSimpleLine>

          <AspectFinancierComplexLine
            title="Frais M.O."
            numberToFormat={aspectFinancier?.montantFraisMo}>
            <ControlledTextField
              size="small"
              name="aspectFinancier.fraisMo"
              select
              defaultValue={0}>
              {FRAIS_MO_OPTIONS.map((v) => (
                <MenuItem key={v.label} value={v.value}>
                  {v.label}
                </MenuItem>
              ))}
            </ControlledTextField>
          </AspectFinancierComplexLine>

          <AspectFinancierComplexLine
            title="Frais de dossier"
            numberToFormat={aspectFinancier?.montantFraisDeDossier}>
            <ControlledTextField
              size="small"
              name="aspectFinancier.fraisDeDossier"
              select
              defaultValue={0}>
              {FRAIS_DE_DOSSIER_OPTIONS.map((v) => (
                <MenuItem key={v.label} value={v.value}>
                  {v.label}
                </MenuItem>
              ))}
            </ControlledTextField>
          </AspectFinancierComplexLine>

          <AspectFinancierSimpleLine title="Total HT" heavyTitle>
            <MontantTypography numberToFormat={aspectFinancier?.totalHt} emphasis />
          </AspectFinancierSimpleLine>

          <AspectFinancierComplexLine title="TVA" numberToFormat={aspectFinancier?.montantTva}>
            <ControlledTextField name="aspectFinancier.tva" select defaultValue={0.2} size="small">
              {TVA_OPTIONS.map((v) => (
                <MenuItem key={v.label} value={v.value}>
                  {v.label}
                </MenuItem>
              ))}
            </ControlledTextField>
          </AspectFinancierComplexLine>

          <AspectFinancierSimpleLine title="Total TTC" heavyTitle>
            <MontantTypography numberToFormat={aspectFinancier?.totalTtc} emphasis />
          </AspectFinancierSimpleLine>
        </Grid>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: { xs: "none", lg: "block" }, transform: "scale(0.95)" }}
        />

        <Grid container size={{ xs: 12, lg: 5.7 }}>
          <AspectFinancierSimpleLine title="Nombre frais d'accès">
            <ControlledNumberField
              size="small"
              name="aspectFinancier.nombreAcces"
              type="entier"
              textAlign="right"
            />
          </AspectFinancierSimpleLine>

          <AspectFinancierSimpleLine title="Montant frais d'accès TTC">
            <ControlledNumberField
              size="small"
              name="aspectFinancier.fraisAcces"
              type="decimal"
              withEndEuro
              textAlign="right"
            />
          </AspectFinancierSimpleLine>

          <AspectFinancierSimpleLine title="Montant Total TTC" heavyTitle>
            <MontantTypography numberToFormat={aspectFinancier?.montantTotalTtc} emphasis />
          </AspectFinancierSimpleLine>

          <DividerGrid />

          <AspectFinancierSimpleLine title="Montant Facturé TTC">
            <ControlledNumberField
              size="small"
              name="aspectFinancier.montantFactureTtc"
              type="decimal"
              withEndEuro
              textAlign="right"
            />
          </AspectFinancierSimpleLine>

          <DividerGrid />

          <AspectFinancierComplexLine
            title="Acompte HT"
            numberToFormat={aspectFinancier?.montantAcompteHt}>
            <ControlledTextField
              size="small"
              name="aspectFinancier.acompte"
              select
              defaultValue={0.5}>
              {ACOMPTE_OPTIONS.map((v) => (
                <MenuItem key={v.label} value={v.value}>
                  {v.label}
                </MenuItem>
              ))}
            </ControlledTextField>
          </AspectFinancierComplexLine>

          <AspectFinancierSimpleLine title="Acompte TTC">
            <MontantTypography numberToFormat={aspectFinancier?.montantAcompteTtc} />
          </AspectFinancierSimpleLine>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AspectFinancierEauForm;
