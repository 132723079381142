import { TypeDossier } from "./Dossier";
import type { DossierAss, DossierEau } from "./Dossier";

export type CourrierType =
  | "AcceptationTravauxAss"
  | "AcceptationTravauxEau"
  | "Achevement"
  | "AccuseReceptionDossierCipaComplet"
  | "AccuseReceptionDossierCipaIncomplet"
  | "AnnexeDossierComplet"
  | "AutorisationRaccordement"
  | "AutorisationDeversement"
  | "BordereauAutorisationDeversement"
  | "Branchement"
  | "Cadre"
  | "ControlePartiel"
  | "ErreurDeDemande"
  | "InstallationCompteurSupplementaire"
  | "ManquePiecesDossierAss"
  | "ManquePiecesDossierEau"
  | "ModificationBranchementEau"
  | "Notice"
  | "RapportVisite"
  | "RelanceAbsenceControle"
  | "RelanceDossierToujoursIncomplet";

/**
 * Type permettant d'extraire uniquement des propriétés dont le nom commence
 * par `date` à partir d'un type générique `T`
 */
type DateKeys<T> = {
  [K in keyof T]: K extends `date${string}` ? K : never;
}[keyof T];

export type CourrierMetadata = {
  /**
   * Type de courrier qui correspond aux valeurs de l'enum de l'API Opale.
   * Seuls les courriers implémentés sont déclarés.
   */
  type: CourrierType;
  dossierType: TypeDossier;
  libelle: string;
  template: string;
  /**
   * Liste de champs (de type "date") à renseigner avec la date du jour de génération du document,
   * lorsque le champ correspondant du dossier n'est pas déjà renseigné.
   */
  datesARenseigner?: Array<DateKeys<DossierEau>> | Array<DateKeys<DossierAss>>;
  avecMarge?: boolean;
};

/**
 * Liste globale des courriers traités par l'application.
 * Sa définition centralisée permet par exemple:
 * - d'en extraire une sous-liste par type de dossier
 * - de construire un dictionnaire renversé pour retrouver un élément à partir d'une clé telle que le nom du template
 */
const AllCourriersDossierDefinitions: Array<CourrierMetadata> = [
  // EAU
  {
    type: "AcceptationTravauxEau",
    dossierType: TypeDossier.EAU,
    libelle: "Acceptation de travaux",
    template: "EXE_R011_Formulaire_AcceptationTravauxEAU.docx",
    datesARenseigner: ["dateEnvoiDevisClient"],
  },
  {
    type: "Branchement",
    dossierType: TypeDossier.EAU,
    libelle: "Branchement",
    template: "Branchement.docx",
  },
  {
    type: "Achevement",
    dossierType: TypeDossier.EAU,
    libelle: "Achèvement",
    template: "ACHEVEMENT.docx",
  },
  {
    type: "ModificationBranchementEau",
    dossierType: TypeDossier.EAU,
    libelle: "Modification branchement",
    template: "Modification branchement EAU.docx",
  },
  {
    type: "InstallationCompteurSupplementaire",
    dossierType: TypeDossier.EAU,
    libelle: "Installation compteur supplémentaire",
    template: "INSTALLATION COMPTEUR SUPPLEMENTAIRE.docx",
  },
  {
    type: "ManquePiecesDossierEau",
    dossierType: TypeDossier.EAU,
    libelle: "Manque pièces dossier EAU",
    template: "MANQUE PIECES EAU.docx",
  },
  // ASS
  {
    type: "AcceptationTravauxAss",
    dossierType: TypeDossier.ASS,
    libelle: "Acceptation de travaux",
    template: "Acceptation Travaux ASS.docx",
    datesARenseigner: ["dateEnvoiDevisClient"],
  },
  {
    type: "Branchement",
    dossierType: TypeDossier.ASS,
    libelle: "Branchement",
    template: "Branchement.docx",
  },
  {
    type: "ManquePiecesDossierAss",
    dossierType: TypeDossier.ASS,
    libelle: "Manque pièces dossier ASS",
    template: "MANQUE PIECES ASS.docx",
  },
  // CIPA
  {
    type: "AccuseReceptionDossierCipaIncomplet",
    dossierType: TypeDossier.CIPA,
    libelle: "Accusé de réception dossier incomplet",
    template: "Accuse de reception incomplet.docx",
  },
  {
    type: "RelanceDossierToujoursIncomplet",
    dossierType: TypeDossier.CIPA,
    libelle: "Relance dossier toujours incomplet",
    template: "Relance incomplet.docx",
  },
  {
    type: "Notice",
    dossierType: TypeDossier.CIPA,
    libelle: "Notice",
    template: "NOTICE EXPLICATIVE.docx",
    avecMarge: true,
  },
  {
    type: "AccuseReceptionDossierCipaComplet",
    dossierType: TypeDossier.CIPA,
    libelle: "Accusé de réception dossier complet",
    template: "Accuse de reception complet.docx",
  },
  {
    type: "AutorisationRaccordement",
    dossierType: TypeDossier.CIPA,
    libelle: "Autorisation de raccordement",
    template: "auto racc.docx",
  },
  {
    type: "AnnexeDossierComplet",
    dossierType: TypeDossier.CIPA,
    libelle: "Annexe dossier complet",
    template: "ANNEXE AUTO RACC ET DEV.docx",
  },
  {
    type: "Cadre",
    dossierType: TypeDossier.CIPA,
    libelle: "Cadre",
    template: "",
    avecMarge: true,
  },
  {
    type: "RapportVisite",
    dossierType: TypeDossier.CIPA,
    libelle: "Rapport de visite",
    template: "Rapport de visite 1.docx",
  },
  {
    type: "RelanceAbsenceControle",
    dossierType: TypeDossier.CIPA,
    libelle: "Relance absence de contrôle",
    template: "RELANCE CONTROLE.docx",
  },
  {
    type: "ControlePartiel",
    dossierType: TypeDossier.CIPA,
    libelle: "Contrôle partiel",
    template: "Controle partiel.docx",
    avecMarge: true,
  },
  {
    type: "AutorisationDeversement",
    dossierType: TypeDossier.CIPA,
    libelle: "Autorisation de déversement",
    template: "ARRETE DEVERSEMENT.docx",
  },
  {
    type: "BordereauAutorisationDeversement",
    dossierType: TypeDossier.CIPA,
    libelle: "Bordereau autorisation de déversement",
    template: "BORDEREAU PRES.docx",
    avecMarge: true,
  },
  {
    type: "ErreurDeDemande",
    dossierType: TypeDossier.CIPA,
    libelle: "Erreur de demande",
    template: "Erreur envoi.docx",
  },
];

function filterCourrierMetadataByType(type: TypeDossier): Array<CourrierMetadata> {
  return AllCourriersDossierDefinitions.filter((d) => d.dossierType === type);
}

/**
 * Dictionnaire de clé/valeur où:
 * - **clé**: nom d'un template de courrier
 * - **valeur**: libellé associé au template de courrier
 */
export const AllCourriersDossierDefinitionsMap = AllCourriersDossierDefinitions.reduce<
  Record<string, string>
>((acc, def) => {
  acc[def.template] = def.libelle;
  return acc;
}, {});

export const CourriersDossierEauDefinitions = filterCourrierMetadataByType(TypeDossier.EAU);
export const CourriersDossierCipaDefinitions = filterCourrierMetadataByType(TypeDossier.CIPA);
export const CourriersDossierAssDefinitions = filterCourrierMetadataByType(TypeDossier.ASS);

export type DocumentDataCadreDto = {
  adresse?: {
    adresseVoie?: string;
    codePostal?: string;
    libelleCommune?: string;
  };
  dossier?: {
    referenceCipa?: string;
    technicienName?: string;
    observations?: string;
    typeRejet?: string;
  };
  demandeur1?: {
    civiliteNomPrenom?: string;
    adresse?: {
      adresseVoie?: string;
      codePostal?: string;
      libelleCommune?: string;
    };
  };
  demandeur2?: {
    civiliteNomPrenom?: string;
  };
};
