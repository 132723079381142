import { Endpoints } from "enums";
import type { ControleDefinition, ControleRapport } from "models";
import { opaleApiClient } from "./apiClients";

/**
 * Retourne la définition d'un rapport de visite de contrôle
 * @param version Version de la définition.
 */
async function getDefinition(version: number = 0): Promise<Array<ControleDefinition>> {
  const url = `/${Endpoints.CONTROLES_CIPA}?version=${version}`;
  return await opaleApiClient.get(url);
}

/**
 * Retourne une liste des contrôles d'un dossier
 * @param chantierId L'identifiant du chantier parent.
 * @param dossierId L'identifiant du dossier.
 */
async function getDossierControles(
  chantierId?: string,
  dossierId?: string
): Promise<Array<ControleRapport>> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}/${Endpoints.CONTROLES_CIPA}`;
  return await opaleApiClient.get(url);
}

/**
 * Créé un contrôle en base de donnée
 * @param data Les données du contrôle.
 * @param chantierId L'identifiant du chantier parent.
 * @param dossierId L'identifiant du dossier.
 */
async function postDossierControle(
  data: ControleRapport,
  chantierId?: string,
  dossierId?: string
): Promise<string> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}/${Endpoints.CONTROLES_CIPA}`;
  return await opaleApiClient.post(url, data);
}

/**
 * Modifie un contrôle en base de donnée
 * @param data Les données mises à jour du contrôle.
 * @param controleId L'identifiant du contrôle.
 * @param chantierId L'identifiant du chantier parent.
 * @param dossierId L'identifiant du dossier.
 */
async function putDossierControle(
  data: ControleRapport,
  controleId: string,
  chantierId?: string,
  dossierId?: string
): Promise<string> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}/${Endpoints.CONTROLES_CIPA}/${controleId}`;
  return await opaleApiClient.put(url, data);
}

/**
 * Crée un nouveau rapport de contrôle à partir de l'identifiant d'un contrôle existant
 * @param controleId L'identifiant du contrôle.
 * @param chantierId L'identifiant du chantier parent.
 * @param dossierId L'identifiant du dossier.
 */
async function cloneDossierControle(
  controleId: string,
  chantierId?: string,
  dossierId?: string
): Promise<string> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}/${Endpoints.CONTROLES_CIPA}/${controleId}/cloner`;
  return await opaleApiClient.post(url);
}

export const controlesService = {
  getDefinition,
  getDossierControles,
  postDossierControle,
  putDossierControle,
  cloneDossierControle,
};
