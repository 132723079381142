import { arrayUtil } from "@sdeapps/react-core";
import type { Perimetre, Transfert } from "models";
import { useCallback } from "react";
import { communesService, perimetreService } from "services";

/**
 * Filtre une liste de périmètres par type, et applique un filtre supplémentaire pour identifier le meilleur périmètre lorsqu'un
 * transfert correspond aux contraintes de spécificité EAU-EX-3 ou ASS-EX-2
 * @param perimetres La liste des périmètres à filtrer selon le type.
 * @param transferts La liste des transferts pour identifier le meilleur périmètre.
 * @param type Le type de périmètre à retourner
 * @returns Une liste de périmètres, filtrée par compétence, dans laquelle un seul périmètre ne peut être présent si un transfert EAU-EX-3 ou ASS-EX-2 correspond.
 */
async function getBestPerimetresByType(
  perimetres: Array<Perimetre>,
  transferts: Array<Transfert>,
  type: "EAU" | "ASS"
): Promise<Array<Perimetre>> {
  if (perimetres == null || perimetres.length === 0) {
    return [];
  }

  // Filtre des périmètres par compétence
  const perimetresByType = perimetres.filter((p) => p.competence === type);
  if (perimetresByType.length === 0) {
    return [];
  }

  // Filtre des transferts par type de compétence du périmètre
  const transfertsByType = transferts.filter((t) => t.competence === type);

  // Recherche de transferts spécifiques par codes de portée
  const codes = type === "EAU" ? ["EAU-EX-3", "EAU-EX-10"] : ["ASS-EX-2", "ASS-EX-10"];
  const perimetresIdsBycode = arrayUtil.dedupArray(
    transfertsByType.filter((t) => codes.includes(t.code)).map((t) => t.idPerimetre)
  );

  if (perimetresIdsBycode.length > 0) {
    const perimetresSelectionnes = perimetresByType.filter((p) =>
      perimetresIdsBycode.includes(p.id)
    );
    // Si le nombre d'identifiant de périmètres ne correspond pas aux périmètres sélectionnés
    // il est fort possible qu'au moins l'un d'eux manque, et donc qu'il faut le recharger.
    if (perimetresSelectionnes.length !== perimetresIdsBycode.length) {
      return await Promise.all(
        perimetresIdsBycode.map(async (id: string) => {
          return await perimetreService.getById(id);
        })
      );
    }
    return perimetresSelectionnes;
  }

  // Toute cette logique pour rien, on retourne les périmètres filtrés.
  return perimetresByType;
}

export interface PerimetresEauAss {
  perimetresEau: Array<Perimetre>;
  perimetresAss: Array<Perimetre>;
}

interface UseDossierPerimetreResult {
  getPerimetresByCommune: (
    communeCodeInsee?: string,
    communeAssocieeDelegueeCodeInsee?: string
  ) => Promise<PerimetresEauAss>;
}

export function useDossierPerimetre(): UseDossierPerimetreResult {
  const getPerimetresByCommune = useCallback(
    async (
      communeCodeInsee?: string,
      communeAssocieeDelegueeCodeInsee?: string
    ): Promise<PerimetresEauAss> => {
      if (communeCodeInsee == null) {
        return { perimetresEau: [], perimetresAss: [] };
      }
      const [transferts, perimetres] = await Promise.all([
        communesService.getAllTransfertsByCommune(communeCodeInsee),
        communesService.getAllPerimetresByCommune(communeCodeInsee),
      ]);

      const transfertsFiltres =
        communeAssocieeDelegueeCodeInsee != null
          ? transferts.filter((t) =>
              t.affectationsCommunesAssocieesDeleguees?.includes(communeAssocieeDelegueeCodeInsee)
            )
          : transferts;

      const perimetresEau = await getBestPerimetresByType(perimetres, transfertsFiltres, "EAU");
      const perimetresAss = await getBestPerimetresByType(perimetres, transfertsFiltres, "ASS");

      return { perimetresEau, perimetresAss };
    },
    []
  );

  return { getPerimetresByCommune };
}
