import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";
import type { FacturationCipa } from "models";

/**
 * Retourne l'objet de facturation d'un dossier CIPA
 * @param chantierId L'identifiant du chantier parent
 * @param dossierId L'identifiant du dossier
 */
async function getByDossier(chantierId: string, dossierId: string): Promise<FacturationCipa> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}/${Endpoints.FACTURATIONS_CIPA}`;
  return await opaleApiClient.get<FacturationCipa, FacturationCipa>(url);
}

/**
 * Remplace les données de facturation existantes pour le dossier CIPA donné.
 * @param chantierId L'identifiant du chantier parent
 * @param dossierId L'identifiant du dossier
 * @param facturation Les nouvelles données de facturation
 */
async function replaceFacturation(
  chantierId: string,
  dossierId: string,
  facturation: FacturationCipa
): Promise<void> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}/${Endpoints.FACTURATIONS_CIPA}`;
  await opaleApiClient.put<FacturationCipa, FacturationCipa>(url, facturation);
}

export const facturationCipaService = {
  getByDossier,
  replaceFacturation,
};
