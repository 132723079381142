import type { ChantierCreateDTO, Chantier, PaginatedQueryChantier, PaginatedResult } from "models";
import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";

/**
 * Crée un nouveau chantier avec une adresse et un ou plusieurs avis.
 * @returns L'identifiant du chantier créé
 */
async function postChantier(data: ChantierCreateDTO): Promise<string> {
  const url = `/${Endpoints.CHANTIERS}`;
  return opaleApiClient.post<string, string>(url, data);
}

/**
 * Retourne les détails d'un chantier.
 * @param id L'identifiant du chantier
 */
async function getChantier(id: string): Promise<Chantier> {
  const url = `/${Endpoints.CHANTIERS}/${id}`;
  return opaleApiClient.get<Chantier, Chantier>(url);
}

/**
 * Retourne une liste de chantiers
 * @returns Tableau de chantiers
 */
async function getAllChantiers(
  filtre?: PaginatedQueryChantier
): Promise<PaginatedResult<Chantier>> {
  let url = `/${Endpoints.CHANTIERS}`;
  if (filtre != null && Object.values(filtre).filter((v) => v != null && v !== "").length > 0) {
    const filtreNotNull = Object.entries(filtre)
      .filter(([_, value]) =>
        Array.isArray(value) ? value.length > 0 : value != null && value !== ""
      )
      .reduce<Record<string, string>>((acc, [key, value]) => {
        if (Array.isArray(value)) {
          acc[key] = value.join(",");
        } else {
          acc[key] = String(value);
        }
        return acc;
      }, {});
    url += `?${new URLSearchParams(filtreNotNull)}`;
  } else {
    url = `/${Endpoints.CHANTIERS}?offset=0&size=10`;
  }
  return opaleApiClient.get<PaginatedResult<Chantier>, PaginatedResult<Chantier>>(url);
}

export const chantierService = {
  postChantier,
  getChantier,
  getAllChantiers,
};
