import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useControleCipa } from "pages/Chantier/providers/ControleCipaProvider";
import { useCallback, useEffect, useState, type ReactNode } from "react";
import { FormSection } from "components/Layout";
import { ControleRapportStatus } from "models";
import { getControleRapportStatusLabel } from "utils/labelUtil";
import { dateUtil } from "@sdeapps/react-core";
import { useDebouncedCallback } from "use-debounce";

export function ControleCipaHeader(): ReactNode {
  const { getRapportHeader: getRapport, updateRapportHeader } = useControleCipa();
  const rapport = getRapport();
  const [observations, setObservations] = useState<string | undefined>(rapport?.observations);
  useEffect(() => {
    setObservations(rapport?.observations);
  }, [rapport?.observations]);
  const updateRapportObservationDebounced = useDebouncedCallback((observations?: string) => {
    if (rapport != null) {
      updateRapportHeader({
        ...rapport,
        observations,
      });
    }
  }, 250);
  const onObservationsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newObservations = e.target.value;
      setObservations(newObservations);
      updateRapportObservationDebounced.cancel();
      updateRapportObservationDebounced(newObservations);
    },
    [updateRapportObservationDebounced]
  );

  return (
    <FormSection title="Visite" size={12}>
      <Grid size={{ xs: 12, sm: 3 }}>
        <DatePicker
          sx={{ width: "100%" }}
          format="dd/MM/yyyy"
          label="Date du contrôle"
          name="dateControle"
          value={
            rapport != null ? dateUtil.fromFormatToDate(rapport?.dateControle, "yyyy-MM-dd") : null
          }
          onChange={(date: Date | null | undefined) => {
            if (rapport == null) {
              console.error("Le rapport courant n'est pas défini");
              return;
            }
            updateRapportHeader({
              ...rapport,
              dateControle: dateUtil.format(date, "yyyy-MM-dd"),
            });
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <FormControl>
          <FormLabel>Conformité</FormLabel>
          <RadioGroup
            row
            value={rapport?.status ?? ""}
            onChange={(e) => {
              if (rapport == null) {
                console.error("Le rapport courant n'est pas défini");
                return;
              }
              updateRapportHeader({
                ...rapport,
                status: e.target.value as ControleRapportStatus,
              });
            }}>
            {Object.values(ControleRapportStatus).map((status) => (
              <FormControlLabel
                key={status}
                label={getControleRapportStatusLabel(status)}
                labelPlacement="end"
                value={status}
                control={<Radio size="small" />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid size={12}>
        <TextField
          label="Observations"
          fullWidth
          multiline
          value={observations ?? ""}
          onChange={onObservationsChange}
        />
      </Grid>
    </FormSection>
  );
}
