import { createContext, useContext, useState } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { graphService } from "services";
import timeUtil from "utils/timeUtil";

const DEBOUNCE_TIME = 500;

export interface TechnicienAvatarContextData {
  getTechnicienAvatar: (idTechnicien: string) => Promise<string | undefined>;
}

const data: TechnicienAvatarContextData = {
  getTechnicienAvatar: async () => "",
};

const TechnicienAvatarContext = createContext(data);

export function TechnicienAvatarProvider({ children }: Readonly<PropsWithChildren>): ReactNode {
  const [technicienAvatars, setTechnicienAvatars] = useState(new Map<string, string>());
  const [loadingTechnicienAvatars, setLoadingTechnicienAvatars] = useState(new Set<string>());

  const getTechnicienAvatar = async (technicienId: string): Promise<string | undefined> => {
    if (technicienAvatars.has(technicienId)) {
      // Si l'avatar du technicien est déjà chargé, on le retourne
      loadingTechnicienAvatars.delete(technicienId);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return technicienAvatars.get(technicienId)!;
    } else if (loadingTechnicienAvatars.has(technicienId)) {
      // Si il est déjà en train de charger, on attend qu'il finisse
      await timeUtil.sleep(DEBOUNCE_TIME);
      return getTechnicienAvatar(technicienId);
    } else {
      // Si il n'est pas en train de charger, on lance le chargement
      setLoadingTechnicienAvatars(loadingTechnicienAvatars.add(technicienId));
      let avatar: string = "";
      try {
        avatar = await graphService.getUserPhoto(technicienId);
      } catch (error) {
      } finally {
        loadingTechnicienAvatars.delete(technicienId);
        setTechnicienAvatars(new Map(technicienAvatars.set(technicienId, avatar)));
      }
      return avatar;
    }
  };

  const data: TechnicienAvatarContextData = {
    getTechnicienAvatar,
  };

  return (
    <TechnicienAvatarContext.Provider value={data}>{children}</TechnicienAvatarContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useTechnicienAvatar(): TechnicienAvatarContextData {
  const context = useContext(TechnicienAvatarContext);

  if (context == null) {
    throw new Error("useTechnicienAvatar must be used within a TechnicienAvatarProvider");
  }

  return context;
}
