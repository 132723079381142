import { Box, Grid2 as Grid, Typography, useMediaQuery } from "@mui/material";
import { useControleCipa } from "pages/Chantier/providers/ControleCipaProvider";
import type { ReactNode } from "react";
import { ControleCipaDetailLine } from ".";
import { FormSection } from "components/Layout";
import { ControleDetailStatusValues } from "models";
import { getControleDetailStatusLabel } from "utils/labelUtil";
import { getStatusGridLayout, getStatusCellWidthVariable } from "./ControleUtils";

export function ControleCipaDetails(): ReactNode {
  const useInlineLayout = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { controlesDefinition } = useControleCipa();

  return (
    <FormSection title="Rapport" size={12}>
      <Grid container size={12} spacing={0}>
        {useInlineLayout && (
          <Grid
            size={12}
            container
            sx={{
              position: "sticky",
              top: 162,
              zIndex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              ...getStatusCellWidthVariable(useInlineLayout),
            }}>
            <Grid>
              <Box sx={getStatusGridLayout(useInlineLayout)}>
                {ControleDetailStatusValues.map((status) => (
                  <Typography
                    key={status}
                    variant="body2"
                    sx={{
                      fontWeight: 800,
                      textAlign: "center",
                    }}>
                    {getControleDetailStatusLabel(status)}
                  </Typography>
                ))}
              </Box>
            </Grid>
          </Grid>
        )}
        {controlesDefinition?.map((controleDefinition) => (
          <ControleCipaDetailLine
            key={controleDefinition.id}
            useInlineLayout={useInlineLayout}
            controleDefinition={controleDefinition}
          />
        ))}
      </Grid>
    </FormSection>
  );
}
