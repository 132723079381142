import type { ReactNode, PropsWithChildren } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";

interface AspectFinancierBigTextLineProps extends PropsWithChildren {
  title?: ReactNode;
  subTitle?: ReactNode;
  heavyTitle?: boolean;
  textAlign?: "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent";
}

export function AspectFinancierBigTextLine({
  title = "",
  subTitle,
  heavyTitle = false,
  textAlign = "right",
  children,
}: Readonly<AspectFinancierBigTextLineProps>): ReactNode {
  return (
    <Grid container size={12} sx={{ alignItems: "center" }} columnSpacing={2}>
      <Grid container size={8} spacing={0} sx={{ justifyItems: "end" }}>
        <Grid size={12}>
          <Typography variant={heavyTitle ? "subtitle2" : undefined} sx={{ textAlign }}>
            {title}
          </Typography>
          {subTitle != null && (
            <Typography variant="caption" sx={{ textAlign, display: "block" }}>
              {subTitle}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid size={4}>{children}</Grid>
    </Grid>
  );
}
