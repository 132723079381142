import { useEffect } from "react";
import type { ReactNode } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ControlledNumberField, ControlledBooleanRadio } from "components/Inputs";
import type { DossierPacModifyDTO } from "models";
import { useFormContext, useWatch } from "react-hook-form";
import { calculService } from "services";
import { useDebouncedCallback } from "use-debounce";
import { useSnackbarErrorHandler } from "utils/errorHandling";
import { AspectFinancierBigTextLine, MontantTypography } from "../AspectFinancier";
import DividerGrid from "../AspectFinancier/DividerGrid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ControlledCheckbox } from "components/Inputs/ControlledCheckbox";

function AspectFinancierPacForm(): ReactNode {
  const { setValue } = useFormContext<DossierPacModifyDTO>();
  const { aspectFinancier } = useWatch<DossierPacModifyDTO>();

  const { catchErrors: catchErrorsCalcul, isLoading: isLoadingCalcul } = useSnackbarErrorHandler();

  const debouncedCalcul = useDebouncedCallback(() => {
    void catchErrorsCalcul(async () => {
      if (aspectFinancier != null) {
        const {
          isSaisieManuelle,
          pacAFacturer,
          pacAFacturerManuelle,
          pacEstimeeAvantTravaux,
          pacEstimeeAvantTravauxManuelle,
          pacMaximale,
          prixAnc,
          totalEquivalentLogementsDomestiques,
          totalEquivalentLogementsEnsemble,
          version,
          ...param
        } = aspectFinancier;

        if (param != null) {
          const res = await calculService.postCalculAspectFinancierPac(param);

          setValue(
            "aspectFinancier.totalEquivalentLogementsDomestiques",
            res.totalEquivalentLogementsDomestiques
          );
          setValue(
            "aspectFinancier.totalEquivalentLogementsEnsemble",
            res.totalEquivalentLogementsEnsemble
          );
          setValue("aspectFinancier.pacMaximale", res.pacMaximale);
          setValue("aspectFinancier.prixAnc", res.prixAnc);
          setValue("aspectFinancier.pacEstimeeAvantTravaux", res.pacEstimeeAvantTravaux);
          setValue("aspectFinancier.pacAFacturer", res.pacAFacturer);
        }
      }
    });
  }, 500);

  useEffect(debouncedCalcul, [
    debouncedCalcul,
    aspectFinancier?.pacLogementSimple,
    aspectFinancier?.pacLogementSupplementaire,
    aspectFinancier?.nombreLogementsDomestiques,
    aspectFinancier?.concerneLogementSupplementaire,
    aspectFinancier?.restaurateur,
    aspectFinancier?.detailMetiersBouche,
    aspectFinancier?.hotellerieSansRepas,
    aspectFinancier?.hotelleriePensionComplete,
    aspectFinancier?.etudiant,
    aspectFinancier?.retraite,
    aspectFinancier?.detailHorsBouche,
    aspectFinancier?.enseignement,
    aspectFinancier?.camping,
    aspectFinancier?.gensDuVoyage,
    aspectFinancier?.jeux,
    aspectFinancier?.cabinetMedical,
    aspectFinancier?.sallesSport,
    aspectFinancier?.salonsCoiffure,
    aspectFinancier?.pressingsLaveries,
    aspectFinancier?.autresActivites,
    aspectFinancier?.branchementEauHt,
  ]);

  return (
    <Grid container size={12} sx={{ justifyContent: "center" }}>
      <Grid
        container
        size={{ xs: 12, lg: 10, xl: 8 }}
        spacing={2}
        columnSpacing={3}
        sx={{ alignItems: "start" }}>
        <Grid sx={{ height: "4px" }} size={12}>
          {isLoadingCalcul && <LinearProgress />}
        </Grid>
        <Grid container alignItems="center" size={12}>
          <AspectFinancierBigTextLine title="Nombre de logements domestiques">
            <ControlledNumberField
              name="aspectFinancier.nombreLogementsDomestiques"
              type="entier"
              textAlign="right"
              size="small"
            />
          </AspectFinancierBigTextLine>
          <AspectFinancierBigTextLine title="Concerne un logement supplémentaire">
            <ControlledBooleanRadio
              name="aspectFinancier.concerneLogementSupplementaire"
              rules={{
                validate: (value) => typeof value === "boolean" || "Ce champ est obligatoire",
              }}
              defaultValue={false}
            />
          </AspectFinancierBigTextLine>
        </Grid>
        <Grid container size={12}>
          <Accordion
            variant="outlined"
            sx={{
              width: "100%",
              flexDirection: "row-reverse",
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ flexDirection: "row-reverse" }}>
              <AspectFinancierBigTextLine title="Total en équivalent logements des activités assimilables au domestique">
                <MontantTypography
                  numberToFormat={aspectFinancier?.totalEquivalentLogementsDomestiques}
                  withEndEuro={false}
                />
              </AspectFinancierBigTextLine>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Restaurateur"
                  subTitle="capacité dégraisseur en repas/jour">
                  <ControlledNumberField
                    name="aspectFinancier.restaurateur"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Commerce de détail, métiers de bouche (transformation sur place)"
                  subTitle="par unité">
                  <ControlledNumberField
                    name="aspectFinancier.detailMetiersBouche"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Hôtellerie/chambres d'hôtes (sans repas)"
                  subTitle="nombre de chambres">
                  <ControlledNumberField
                    name="aspectFinancier.hotellerieSansRepas"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Hôtellerie/chambre d'hôtes (pension complète)"
                  subTitle="nombre de chambres">
                  <ControlledNumberField
                    name="aspectFinancier.hotelleriePensionComplete"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Hébergement, congrégrations, résidences pour étudiants ou militaires"
                  subTitle="par habitant">
                  <ControlledNumberField
                    name="aspectFinancier.etudiant"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Maisons de retraite, maisons de soins, centres pénitentiaires"
                  subTitle="nombre de résidents à pleine charge">
                  <ControlledNumberField
                    name="aspectFinancier.retraite"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Commerce de détail (hors métiers de bouche avec transformation sur place)"
                  subTitle="par unité">
                  <ControlledNumberField
                    name="aspectFinancier.detailHorsBouche"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Enseignement"
                  subTitle="nombre d'élèves">
                  <ControlledNumberField
                    name="aspectFinancier.enseignement"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Camping"
                  subTitle="nombre d'emplacements">
                  <ControlledNumberField
                    name="aspectFinancier.camping"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Aire de gens du voyage"
                  subTitle="nombre d'emplacements">
                  <ControlledNumberField
                    name="aspectFinancier.gensDuVoyage"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Etablissements de jeux, débits de boissons, culture, loisirs"
                  subTitle="par WC ou urinoir">
                  <ControlledNumberField
                    name="aspectFinancier.jeux"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Cabinet médical"
                  subTitle="unités">
                  <ControlledNumberField
                    name="aspectFinancier.cabinetMedical"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Salles de sport"
                  subTitle="par 4 douches ou par 1 WC/urinoir">
                  <ControlledNumberField
                    name="aspectFinancier.sallesSport"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Salons de coiffure"
                  subTitle="par point d'eau">
                  <ControlledNumberField
                    name="aspectFinancier.salonsCoiffure"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Pressings et laveries"
                  subTitle="par tranche de consommation prévue de 450 L/j">
                  <ControlledNumberField
                    name="aspectFinancier.pressingsLaveries"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>

                <AspectFinancierBigTextLine
                  textAlign="left"
                  title="Autres activités mentionnées à l'annexe 1 du 21/12/2007 - activités
                    administratives ou non polluantes"
                  subTitle="nombre cumulé de cabinets et d'urinoirs">
                  <ControlledNumberField
                    name="aspectFinancier.autresActivites"
                    type="entier"
                    textAlign="right"
                    size="small"
                  />
                </AspectFinancierBigTextLine>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid container alignItems="center" size={12}>
          <AspectFinancierBigTextLine
            title="Total en équivalent logements de l'ensemble de l'opération"
            subTitle="Minimum 1 et arrondi à l'unité">
            <Typography sx={{ textAlign: "right", p: 2, paddingY: 1, pr: 4 }}>
              {aspectFinancier?.totalEquivalentLogementsEnsemble ?? 0}
            </Typography>
          </AspectFinancierBigTextLine>
          <AspectFinancierBigTextLine title="Coût total du branchement EU HT">
            <ControlledNumberField
              name="aspectFinancier.branchementEauHt"
              type="decimal"
              withEndEuro
              textAlign="right"
              size="small"
            />
          </AspectFinancierBigTextLine>
          <AspectFinancierBigTextLine title="PAC maximale" heavyTitle>
            <MontantTypography numberToFormat={aspectFinancier?.pacMaximale} emphasis />
          </AspectFinancierBigTextLine>
          <AspectFinancierBigTextLine title="Prix ANC équivalent estimé" heavyTitle>
            <MontantTypography numberToFormat={aspectFinancier?.prixAnc} emphasis />
          </AspectFinancierBigTextLine>

          <DividerGrid />

          <AspectFinancierBigTextLine
            title={
              <label htmlFor="saisie-manuelle" style={{ cursor: "pointer" }}>
                Saisie manuelle
              </label>
            }>
            <ControlledCheckbox
              id="saisie-manuelle"
              name="aspectFinancier.isSaisieManuelle"
              defaultValue={false}
              sx={{ marginY: -1 }}
            />
          </AspectFinancierBigTextLine>

          <AspectFinancierBigTextLine title="PAC estimée avant travaux" heavyTitle>
            {aspectFinancier?.isSaisieManuelle === true ? (
              <ControlledNumberField
                name="aspectFinancier.pacEstimeeAvantTravauxManuelle"
                type="decimal"
                withEndEuro
                textAlign="right"
                size="small"
              />
            ) : (
              <MontantTypography
                numberToFormat={aspectFinancier?.pacEstimeeAvantTravaux}
                emphasis
              />
            )}
          </AspectFinancierBigTextLine>
          <AspectFinancierBigTextLine title="PAC à facturer" heavyTitle>
            {aspectFinancier?.isSaisieManuelle === true ? (
              <ControlledNumberField
                name="aspectFinancier.pacAFacturerManuelle"
                type="decimal"
                withEndEuro
                textAlign="right"
                size="small"
              />
            ) : (
              <MontantTypography numberToFormat={aspectFinancier?.pacAFacturer} emphasis />
            )}
          </AspectFinancierBigTextLine>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AspectFinancierPacForm;
