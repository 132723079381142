import type { ReactNode, PropsWithChildren } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import { formatMontant } from "utils/financeUtil";

interface AspectFinancierComplexLineProps extends PropsWithChildren {
  title: string;
  heavyTitle?: boolean;
  numberToFormat: string | number | undefined;
}

export function AspectFinancierComplexLine({
  title = "",
  heavyTitle = false,
  numberToFormat,
  children,
}: Readonly<AspectFinancierComplexLineProps>): ReactNode {
  return (
    <Grid container size={12} sx={{ alignItems: "center" }} columnSpacing={2}>
      <Grid size={5}>
        <Typography variant={heavyTitle ? "subtitle2" : undefined} sx={{ textAlign: "right" }}>
          {title}
        </Typography>
      </Grid>
      <Grid size={3}>{children}</Grid>
      <Grid size={4}>
        <Typography sx={{ textAlign: "right", pr: 2 }}>
          {formatMontant({}, numberToFormat, true)}
        </Typography>
      </Grid>
    </Grid>
  );
}
