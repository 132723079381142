import type { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import NavigationMenu from "./Header/NavigationMenu";
import { Container, Grid2 as Grid } from "@mui/material";
import { useDocumentTitle, usePersistentNavigationMenu } from "hooks";

export function Root(): ReactNode {
  useDocumentTitle();
  const { isNavMenuOpen, setIsNavMenuOpen } = usePersistentNavigationMenu();

  return (
    <Grid container size={1}>
      <Grid size="auto">
        <NavigationMenu isOpen={isNavMenuOpen} onOpen={setIsNavMenuOpen} />
      </Grid>
      <Grid size="grow" component="main">
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </Grid>
    </Grid>
  );
}
