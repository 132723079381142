import { useCallback } from "react";
import type { ReactNode } from "react";
import { ToastMessages } from "enums";
import type { CourrierMetadata, Chantier, Dossier, CourrierType } from "models";
import { enqueueSnackbar } from "notistack";
import { courriersService, dossierService, filesService } from "services";
import { useSnackbarErrorHandler, SdeappsErrorNames } from "utils/errorHandling";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { sharepointService } from "services/sharepointService";
import { Button } from "@mui/material";
import patchUtils from "utils/patchUtils";
import { useDossier } from "providers";

interface DocumentGenerationButtonProps {
  courrierMetaData: CourrierMetadata;
  chantier: Chantier;
  dossier: Dossier;
  devisEauId?: string;
  devisAssId?: string;
  beforeGeneration?: () => Promise<void>;
  afterGeneration?: () => void;
}

export function DocumentGenerationButton({
  courrierMetaData: { type, template, libelle, datesARenseigner },
  chantier,
  dossier,
  devisEauId,
  devisAssId,
  beforeGeneration,
  afterGeneration,
}: Readonly<DocumentGenerationButtonProps>): ReactNode {
  const { updateDossier } = useDossier();
  const { catchErrors, isLoading } = useSnackbarErrorHandler({
    [SdeappsErrorNames.Locked]: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.LOCKED_COURRIER,
      });
    },
  });

  const sendGenerateDocument = useCallback(
    (typeCourrier: CourrierType, template: string, openFile?: "browser" | "desktop") => {
      return function () {
        async function generateDocument(): Promise<void> {
          if (beforeGeneration != null) {
            await beforeGeneration();
          }
          const { url: sharepointFileUrl, generatedFileName } =
            await courriersService.genererCourrier(
              typeCourrier,
              template,
              chantier,
              dossier,
              devisEauId,
              devisAssId
            );
          enqueueSnackbar({
            variant: "success",
            message: `Le courrier ${libelle} a été généré avec succès`,
          });
          let hasDatesToUpdate = false;
          if (datesARenseigner != null && datesARenseigner.length > 0) {
            const tempDossierWithDates = datesARenseigner.reduce<Record<string, Date>>(
              (acc, dateProp) => {
                if (dateProp != null) {
                  const datePropValue = Object.hasOwn(dossier, dateProp)
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (dossier as any)[dateProp]
                    : undefined;
                  // Ne renseigne la propriété que si l'actuelle valeur est null ou vide
                  if (datePropValue == null || datePropValue === "") {
                    acc[dateProp] = new Date();
                  }
                }
                return acc;
              },
              {}
            );
            const patch = patchUtils.toPatchData(tempDossierWithDates);
            hasDatesToUpdate = patch.length > 0;
            if (hasDatesToUpdate) {
              await dossierService.patchDossier(chantier.id, dossier.id, patch);
            }
          }
          if (openFile === "browser") {
            sharepointService.openSharepointFileInBrowser(sharepointFileUrl);
          } else if (openFile === "desktop") {
            sharepointService.openSharepointFileInDesktopApp(
              filesService.getCourrierUrl(dossier, chantier, generatedFileName)
            );
          }
          if (afterGeneration != null) {
            afterGeneration();
          }
          if (hasDatesToUpdate) {
            updateDossier();
          }
        }

        void catchErrors(generateDocument);
      };
    },
    [
      catchErrors,
      beforeGeneration,
      chantier,
      dossier,
      devisEauId,
      devisAssId,
      libelle,
      datesARenseigner,
      afterGeneration,
      updateDossier,
    ]
  );

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={sendGenerateDocument(type, template, "desktop")}
      startIcon={<NoteAddIcon color="primary" />}
      loading={isLoading}
      loadingPosition="start">
      {libelle}
    </Button>
  );
}
