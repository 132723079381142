import type { ControleDefinition } from "./ControleDefinition";

export enum ControleDetailStatus {
  ConformeVuSurPlace = "ConformeVuSurPlace",
  ConformeVuSurPhoto = "ConformeVuSurPhoto",
  ResteAVoir = "ResteAVoir",
  ResteAVoirPartiel = "ResteAVoirPartiel",
  NonConformeContreVisite = "NonConformeContreVisite",
  NonVisible = "NonVisible",
  NonConcerne = "NonConcerne",
}

export const ControleDetailStatusValues: Array<ControleDetailStatus> =
  Object.values(ControleDetailStatus);

export type ControleDetail = {
  id?: string;
  status?: ControleDetailStatus;
  userInput?: string;
  userComment?: string;
  controleId?: string;
  definitionId: string;
};

export type ControleDetailWithDefinition = ControleDetail & {
  definition: ControleDefinition;
};
