import { Link } from "@mui/material";
import type { ReactNode } from "react";

interface LinkAvisProps {
  id: string;
}

function LinkAvis({ id }: Readonly<LinkAvisProps>): ReactNode {
  return (
    <Link
      variant="inherit"
      target="_blank"
      href={`https://vheuxds4ci.sap.sdeacloud.net:44300/sap/bc/gui/sap/its/webgui?~transaction=iw53%20RIWO00-QMNUM=${id}#`}>
      {id}
    </Link>
  );
}

export default LinkAvis;
