import {
  Box,
  Button,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { grey } from "@mui/material/colors";
import { Authorization } from "@sdeapps/react-core";
import { ControlledNumberField, ControlledTextField } from "components/Inputs";
import { FormSection, PageLayout } from "components/Layout";
import { ApplicationRole } from "constants/ApplicationRole";
import { TarifsCipa } from "constants/TarifsCipa";
import { ToastMessages } from "enums";
import type { TarifCipaBase } from "models";
import { enqueueSnackbar } from "notistack";
import { useEffect, type ReactNode } from "react";
import {
  FormProvider,
  type SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { tarifsCipaService } from "services/tarifsCipaService";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";

interface TarifsForm {
  annee: number;
  tarifs: Array<TarifCipa>;
}

interface TarifCipa extends TarifCipaBase {
  valeurAnneeSelectionnee?: number;
  valeurAnneePrecedente?: number;
}

function genererTableauAnnees(): Array<number> {
  const annees: Array<number> = [];
  const anneeCourante = new Date().getFullYear();

  annees.push(anneeCourante + 1); // année prochaine
  annees.push(anneeCourante); // année en cours
  annees.push(anneeCourante - 1); // année précédente

  return annees;
}
const annees = genererTableauAnnees();

function GestionTarifsCipaPage(): ReactNode {
  const formMethods = useForm<TarifsForm>({
    defaultValues: {
      annee: undefined,
      tarifs: [],
    },
  });
  const { control, handleSubmit, resetField } = formMethods;

  const { fields, replace } = useFieldArray({
    name: "tarifs",
    control,
  });

  const anneeSelectionnee = useWatch({ control, name: "annee" });
  const tarifs = useWatch({ control, name: "tarifs" });
  const { catchErrors, isLoading } = useErrorHandler({ defaultIsLoading: false });

  useEffect(() => {
    async function getTarifsForAnnee(): Promise<void> {
      resetField("tarifs");
      if (anneeSelectionnee != null) {
        const tarifsAnneeSelectionneePromise = tarifsCipaService.getByAnnee(anneeSelectionnee);
        const tarifsAnneePrecedentePromise = tarifsCipaService.getByAnnee(anneeSelectionnee - 1);

        const [tarifsAnneeSelectionnee, tarifsAnneePrecedente] = await Promise.all([
          tarifsAnneeSelectionneePromise,
          tarifsAnneePrecedentePromise,
        ]);

        const tarifsAvecDescriptions: Array<TarifCipa> = TarifsCipa.reduce<Array<TarifCipa>>(
          (tarifsAvecDescriptions, tarif) => {
            const tarifAvecDescription: TarifCipa = {
              ...tarif,
              valeurAnneeSelectionnee: tarifsAnneeSelectionnee[tarif.code],
              valeurAnneePrecedente: tarifsAnneePrecedente[tarif.code],
            };
            tarifsAvecDescriptions.push(tarifAvecDescription);
            return tarifsAvecDescriptions;
          },
          []
        );
        replace(tarifsAvecDescriptions);
      }
    }

    void catchErrors(getTarifsForAnnee);
  }, [anneeSelectionnee, catchErrors, replace, resetField]);

  const sendData: SubmitHandler<TarifsForm> = async (form) => {
    const data: Record<string, number> = {};
    for (const tarif of form.tarifs) {
      if (tarif.valeurAnneeSelectionnee !== undefined)
        data[tarif.code] = tarif.valeurAnneeSelectionnee;
    }

    await catchErrors(async () => {
      await tarifsCipaService.replaceTarifs(form.annee, data);
    });

    enqueueSnackbar({
      variant: "success",
      message: ToastMessages.UPDATED_TARIFS,
    });
  };

  return (
    <Authorization roles={ApplicationRole.ADMINISTRATOR}>
      <PageLayout pageTitle="Gestion des tarifs CIPA">
        <FormProvider {...formMethods}>
          <Grid
            container
            spacing={2}
            component="form"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(sendData, (errors) => {
              const message = errors.tarifs?.root?.message;
              enqueueSnackbar({
                variant: "error",
                message: message ?? ToastMessages.ERROR_RETRY,
              });
            })}
            size={12}
            alignItems="flex-end">
            <FormSection title="Année *">
              <ControlledTextField
                select
                control={control}
                label="Année"
                placeholder="2025"
                name="annee">
                {annees.map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </FormSection>

            <FormSection title="Tarifs">
              <Box sx={{ position: "relative", width: "100%" }}>
                {anneeSelectionnee == null && (
                  <Typography variant="caption">
                    Veuillez sélectionner une année pour laquelle renseigner les tarifs CIPA.
                  </Typography>
                )}
                {anneeSelectionnee != null && (tarifs == null || tarifs.length === 0) && (
                  <Typography variant="caption">Pas de tarifs.</Typography>
                )}
                {anneeSelectionnee != null && tarifs !== null && tarifs.length > 0 && (
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                      border: `1px solid ${grey[300]}`,
                      position: "relative",
                      mb: 5,
                    }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ paddingY: 2 }}>
                            <Typography fontWeight={600}>Code SAP</Typography>
                          </TableCell>
                          <TableCell sx={{ paddingY: 2 }}>
                            <Typography fontWeight={600}>Nom</Typography>
                          </TableCell>
                          <TableCell sx={{ paddingY: 2 }}>
                            <Typography fontWeight={600}>Tarif {anneeSelectionnee - 1}</Typography>
                          </TableCell>
                          <TableCell sx={{ paddingY: 2 }}>
                            <Typography fontWeight={600}>Tarif {anneeSelectionnee}</Typography>
                          </TableCell>
                          <TableCell sx={{ paddingY: 2 }}>
                            <Typography fontWeight={600}>Unité</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fields.map((field, index) => {
                          return (
                            <TableRow key={field.code}>
                              <TableCell>
                                <Typography>{field.code}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{field.description}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{field.valeurAnneePrecedente}</Typography>
                              </TableCell>
                              <TableCell>
                                <ControlledNumberField
                                  name={`tarifs.${index}.valeurAnneeSelectionnee`}
                                  type="decimal"
                                  textAlign="right"
                                />
                              </TableCell>
                              <TableCell>
                                <Typography>{field.unit}</Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </FormSection>
            <FormSection>
              <Grid container spacing={2} justifyContent="flex-end" size={12}>
                <Grid size="auto">
                  <Button
                    variant="text"
                    color="error"
                    href="/admin"
                    disabled={isLoading || tarifs == null || tarifs.length === 0}>
                    Annuler
                  </Button>
                </Grid>
                <Grid size="auto">
                  <Button
                    variant="outlined"
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading || tarifs == null || tarifs.length === 0}>
                    Enregistrer
                  </Button>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
        </FormProvider>
      </PageLayout>
    </Authorization>
  );
}

const GestionTarifsCipaPageWithErrorBoundary = withPageErrorBoundary(GestionTarifsCipaPage);

export default GestionTarifsCipaPageWithErrorBoundary;
