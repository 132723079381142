import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";
import type { TarifsCipaDictionary } from "models";

/**
 * Retourne la liste des tarifs CIPA pour une année donnée
 * @param annee L'année de validité des tarifs recherchés
 */
async function getByAnnee(annee: number): Promise<TarifsCipaDictionary> {
  const url = `/${Endpoints.TARIFS_CIPA}/${annee}`;
  const tarifs = await opaleApiClient.get<TarifsCipaDictionary, TarifsCipaDictionary>(url);

  return tarifs;
}

/**
 * Remplace les tarifs existants pour l'année donnée.
 * @param annee L'année de référence des tarifs
 * @param tarifs Les nouveaux tarifs
 */
async function replaceTarifs(annee: number, tarifs: TarifsCipaDictionary): Promise<void> {
  const url = `/${Endpoints.TARIFS_CIPA}/${annee}`;
  await opaleApiClient.put<TarifsCipaDictionary, TarifsCipaDictionary>(url, tarifs);
}

export const tarifsCipaService = {
  getByAnnee,
  replaceTarifs,
};
