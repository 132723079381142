export enum TypeDemandeur {
  Societe = "Societe",
  Particulier = "Particulier",
}

export interface Demandeur {
  $type: TypeDemandeur;
  civilite: string;
  adresseNumero: string;
  adresseRue: string;
  adresseCodePostal: string;
  adresseCommune: string;
  adressePays: string;
  telephone: string;
  portable: string;
  email: string;
  codeSap: string;
}
export interface DemandeurParticulier extends Demandeur {
  $type: TypeDemandeur.Particulier;
  nom: string;
  prenom: string;
}

export interface DemandeurSociete extends Demandeur {
  $type: TypeDemandeur.Societe;
  siret: string;
  raisonSociale: string;
}
