import Grid from "@mui/material/Grid2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  Link,
  TablePagination,
  Button,
  Box,
} from "@mui/material";
import { useEffect, useState, type ReactNode } from "react";
import DossierChip from "./components/DossierChip";
import { CommuneAutoCompleteWithErrorBoundary } from "components/Inputs";
import { PageLayout } from "components/Layout";
import { LoadingScreen } from "components/Loading";
import type { PaginatedQueryAvis, Avis, PaginatedResult, Commune } from "models";
import { TypeDossier } from "models";
import { avisService, communesService } from "services";
import { useDebouncedCallback } from "use-debounce";
import { routesConfig } from "config/app-config";
import {
  withPageErrorBoundary,
  useSnackbarErrorHandler,
  useErrorHandler,
} from "utils/errorHandling";
import TypeDossierSelect from "./components/TypeDossierSelect";
import { getDemandeurDisplayName } from "utils/demandeurUtil";
import LinkAvis from "components/LinkAvis";

const typesDossier = Object.keys(TypeDossier).filter((t) => t !== TypeDossier.PAC);

const DEFAULT_OFFSET = 0;
const DEFAULT_PAGE_SIZE = 10;

function RechercheAvisPage(): ReactNode {
  const { catchErrors, isLoading } = useSnackbarErrorHandler({
    defaultIsLoading: true,
  });
  const [results, setResults] = useState<PaginatedResult<Avis>>({ result: [], totalCount: 0 });
  const [query, setQuery] = useState<PaginatedQueryAvis>({
    offset: DEFAULT_OFFSET,
    size: DEFAULT_PAGE_SIZE,
  });
  const debouncedFetchAvis = useDebouncedCallback((queryParams: PaginatedQueryAvis) => {
    void catchErrors(async () => {
      const result = await avisService.getAllAvis(queryParams);
      setResults(result);
    });
  }, 500);
  useEffect(() => {
    debouncedFetchAvis(query);
  }, [debouncedFetchAvis, query]);
  const handleFiltreValue =
    (key: keyof PaginatedQueryAvis) =>
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setQuery({ ...query, offset: DEFAULT_OFFSET, [key]: evt.target.value });
    };

  const [communes, setCommunes] = useState<Array<Commune>>([]);
  const {
    isLoading: isCommunesLoading,
    catchErrors: catchCommunesErrors,
    error: errorCommunes,
  } = useErrorHandler();
  useEffect(() => {
    void catchCommunesErrors(async (): Promise<void> => {
      setCommunes(await communesService.getAll());
    });
  }, [catchCommunesErrors]);

  return (
    <PageLayout pageTitle="Recherche d'avis">
      <Grid size="grow" container spacing={2}>
        <Typography component={Grid} size={12} color="primary" variant="body1" fontWeight={600}>
          Filtres
        </Typography>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <TextField
            size="small"
            fullWidth
            label="Id"
            name="id"
            value={query.idAvis ?? ""}
            onChange={handleFiltreValue("idAvis")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <TypeDossierSelect
            size="small"
            fullWidth
            label="Type de demande"
            name="type"
            value={query.type ?? ""}
            options={typesDossier}
            onChange={handleFiltreValue("type")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <CommuneAutoCompleteWithErrorBoundary
            size="small"
            selectedCommuneId={query.codeInseeCommune}
            onChange={(commune) => {
              setQuery({
                ...query,
                codeInseeCommune: commune?.id,
              });
            }}
            communes={communes}
            isLoading={isCommunesLoading}
            error={errorCommunes}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <TextField
            size="small"
            fullWidth
            label="Demandeur"
            name="demandeur"
            value={query.demandeur ?? ""}
            onChange={handleFiltreValue("demandeur")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <TextField
            select
            size="small"
            fullWidth
            label="Avec dossier ?"
            name="onlyWithDossier"
            value={query.onlyWithDossier ?? ""}
            onChange={handleFiltreValue("onlyWithDossier")}>
            <MenuItem value="">Tous</MenuItem>
            <MenuItem value="true">Oui</MenuItem>
            <MenuItem value="false">Non</MenuItem>
          </TextField>
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <Button
            onClick={() => {
              setQuery({
                offset: DEFAULT_OFFSET,
                size: DEFAULT_PAGE_SIZE,
                demandeur: "",
                idAvis: "",
              });
            }}
            fullWidth
            sx={{ background: "white" }}
            color="error"
            variant="outlined">
            Réinitialiser les filtres
          </Button>
        </Grid>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={600}>Id</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>Type</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>Commune</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>Demandeur(s)</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>Dossier</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.result.map((avis) => (
                <TableRow key={avis.idAvis}>
                  <TableCell>
                    <Typography>
                      <LinkAvis id={avis.idAvis} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <DossierChip sx={{ marginY: -1 }} type={avis.typeDemande} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{avis.nomCommune}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {getDemandeurDisplayName(avis.demandeur1)}
                      {avis.demandeur2 != null && `/ ${getDemandeurDisplayName(avis.demandeur2)}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {avis.idChantier != null && avis.idDossier != null && (
                      <Link
                        href={routesConfig.chantierDossier.getParameterPath(
                          avis.idChantier,
                          avis.idDossier
                        )}>
                        <DossierChip
                          sx={{ marginY: -1 }}
                          reference={avis.dossierReference}
                          type={avis.typeDemande}
                        />
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={Math.ceil(query.offset / query.size)}
          rowsPerPage={query.size}
          count={results.totalCount}
          onPageChange={(_, pageNum) => {
            setQuery({
              ...query,
              offset: query.size * pageNum,
            });
          }}
          onRowsPerPageChange={(evt) => {
            setQuery({
              ...query,
              offset: DEFAULT_OFFSET,
              size: parseInt(evt.target.value, 10),
            });
          }}
        />
      </Grid>
      {isLoading && (
        <Box sx={{ position: "absolute", left: 0, right: 0, top: 0, pointerEvents: "none" }}>
          <LoadingScreen />
        </Box>
      )}
    </PageLayout>
  );
}

export const RechercheAvisPageWithErrorBoundary = withPageErrorBoundary(RechercheAvisPage);
