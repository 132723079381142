import Grid from "@mui/material/Grid2";
import { PageLayout } from "components/Layout";
import type { ReactNode } from "react";
import { withPageErrorBoundary } from "utils/errorHandling";
import { AdministrationArea } from "./components/AdministrationArea";
import { routesConfig } from "config/app-config";
import { NavigationButton } from "./components/NavigationButton";
import { Authorization } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";

function AdministrationPage(): ReactNode {
  return (
    <Authorization roles={ApplicationRole.ADMINISTRATOR}>
      <Grid container spacing={2}>
        <PageLayout pageTitle="Administration" />
        <AdministrationArea
          title="Tarifs"
          items={[
            <NavigationButton
              key="tarifs-cipa"
              libelle="Tarifs CIPA"
              href={routesConfig.tarifsCipa.path}
              color="cipa"
            />,
          ]}
        />
      </Grid>
    </Authorization>
  );
}

const AdministrationPageWithErrorBoundary = withPageErrorBoundary(AdministrationPage);

export default AdministrationPageWithErrorBoundary;
