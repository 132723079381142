import { createContext } from "react";
import type { Chantier, ChantiersSearchFiltre, PaginatedQuery, PaginatedResult } from "models";

export type ChantiersSearchData = {
  isLoading: boolean;
  pagination: PaginatedQuery;
  paginatedChantiers: PaginatedResult<Chantier>;
  hasMoreResult: boolean;
  filtres: ChantiersSearchFiltre;
  defaultFiltres: ChantiersSearchFiltre;
  updateFiltres: (filtres: ChantiersSearchFiltre) => void;
  loadNextPage: VoidFunction;
};

export const ChantiersSearchContext = createContext<ChantiersSearchData>({
  isLoading: true,
  pagination: { offset: 0, size: 3 },
  paginatedChantiers: { result: [], totalCount: 0 },
  hasMoreResult: false,
  filtres: { statuts: [] },
  defaultFiltres: { statuts: [] },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFiltres: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadNextPage: () => {},
});
